import React, { useEffect, useState } from "react";
import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Courses } from "../../Types/courses";
import { api } from "../../Utils/ApiService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 32,
      paddingBottom: 64,
    },
  })
);

export default function Organization() {
  const classes = useStyles();

  const [info, setInfo] = useState<Courses | undefined>(undefined);

  useEffect(() => {
    api.get(
      "/fileContent",
      { location: "config", file: "courses.json" },
      {
        success: (c) => {
          setInfo(JSON.parse(c));
        },
        error: () => console.log("Při načítání kurzů došlo k chybě"),
      }
    );
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <h2 id="organizace">ORGANIZAČNÍ PODMÍNKY</h2>
        <p>
          Nejintenzivnější formou výuky je bezpochyby výuka individuální, ideální pro zaneprázdněné jedince či nezávislé
          osobnosti, které vyžadují nejvyšší možnou péči přesně šitou na míru jejich pokročilosti a zároveň chtějí aktivně
          ovlivňovat náplň výuky podle svých aktuálních profesních nebo soukromých požadavků.
        </p>
        <p>
          Oblíbené jsou také menší skupiny studentů stejné pokročilosti, pokud se shodnou na časových a místních podmínkách
          výuky. Skupinová práce je pak motivací k rychlejšímu pokroku a příznivá cena zase kompenzací za sdílenou péči
          lektora. 
        </p>
        <p>
          Firmám navíc nabízím jazykové audity pro určení stávající jazykové úrovně, pomoc s navržením dlouhodobého studijního
          plánu i pravidelné hodnocení dosaženého pokroku.
        </p>
        <p>
          Výuka probíhá buď distančně / živě online na populárních videoplatformách, např. Zoom či Skype, anebo prezenčně v
          prostorách vaší firmy nebo v pohodlí vašeho domova.
        </p>
        <p>
          Realizuji dlouhodobé pravidelné kurzy s délkou vyučovacího bloku alespoň 2 vyuč. hod. týdně probíhající minimálně 4
          měsíce nebo nárazové intenzivní kurzy v rozsahu několika dnů dle dohody. Většinou jde o přípravu na důležitou událost
          založenou na jazykové dovednosti, např. zkoušku, pracovní pohovor, zahraniční kongres, služební cestu, veletrh apod.
        </p>
        <p>{info?.companyPrices || ""}</p>
      </Container>
    </div>
  );
}
