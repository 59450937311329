import React, { useState } from "react";
import { Container, createStyles, Grid, Hidden, makeStyles, Theme } from "@material-ui/core";
import { useHistory } from "react-router";
import Expand from "react-expand-animated";
import EFButton from "../../Components/EFButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFFAF6",
      padding: 32,
    },
    routerImage: {
      width: "100%",
    },
    imageRootLeft: {
      width: "58%",
      height: 286,
      cursor: "pointer",
      position: "relative",
      overflow: "hidden",
      transform: "skew(-20deg)",
      margin: "0 0 0 -3.7%",
      "&:hover": {
        width: "88%",
        margin: "0 0 0 -5.6%",
      },
    },
    imageLeft: {
      opacity: 0.64,
      backgroundImage: "url(/img/publicTution.jpg)",
      backgroundSize: "44vw",
      backgroundPositionX: "left",
      backgroundPositionY: "center",
      position: "absolute",
      top: -30,
      left: "8.3%",
      right: "-8.3%",
      bottom: -30,
      transform: "skew(20deg)",
      "&:hover": {
        opacity: 0.88,
      },
    },
    imageRootRight: {
      width: "58%",
      height: 286,
      cursor: "pointer",
      position: "relative",
      overflow: "hidden",
      transform: "skew(-20deg)",
      margin: "0 0 0 0.5%",
      "&:hover": {
        width: "88%",
        // margin: "0 0 0 -5.6%",
      },
    },
    imageRight: {
      opacity: 0.64,
      backgroundImage: "url(/img/businessTution.jpg)",
      backgroundSize: "44vw",
      backgroundPositionX: "right",
      backgroundPositionY: "center",
      position: "absolute",
      top: -30,
      left: "-8.3%",
      right: "8.3%",
      bottom: -30,
      transform: "skew(20deg)",
      "&:hover": {
        opacity: 0.88,
      },
    },
    router: {
      display: "flex",
      width: "105%",
      borderRadius: 16,
    },
    routerImageText: {
      position: "absolute",
      fontFamily: "Ludicrous",
      color: "#122e5c",
      fontSize: "2.5rem",
      top: 8,
      left: "calc(20px + 6%)",
      opacity: "100%",
      [theme.breakpoints.up("lg")]: {
        transform: "skew(20deg)",
      },
      textShadow: "2px 2px white, -2px -2px white",
    },
    singleRouterImage: {
      cursor: "pointer",
      width: "100%",
      backgroundSize: "100vw",
      backgroundPositionX: "right",
      backgroundPositionY: "center",
      height: "42vh",
      position: "relative",
      borderRadius: 8,
      "&:hover": {
        opacity: "80%",
      },
    },
  })
);

export default function Router() {
  const classes = useStyles();
  const history = useHistory();

  const [detailsExpandedA, setDetailsExpandedA] = useState(false);
  const [detailsExpandedB, setDetailsExpandedB] = useState(false);
  const [detailsExpandedC, setDetailsExpandedC] = useState(false);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <h2 id="potreby">IDENTIFIKACE POTŘEB</h2>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={6}>
            <p>
              Jste jednotlivec schopný se časově přizpůsobit a vybrat si ze stardardní nabídky skupinových kurzů navržených
              podle jednotlivých jazykových úrovní?
            </p>
            <p>
              Je pro vás práce ve skupině motivací a nejnižší cena na trhu vám bohatě vynahradí sdílenou péči lektora o
              ostatními studenty ve skupině?
            </p>
            <p>Potřebujete systematické a pevné vedení s vyváženým prostorem pro vlastní kreativitu a zpětnou vazbu?</p>
            <p>
              Odrazuje vás neucelenost a nelogičnost vaší současné či minulé výuky? Nerozumíte, když na vás druzí mluví
              anglicky?
            </p>
            <p>Bojíte se sami promluvit a třeba vytvořit otázku je pro vás doslova nadlidský úkon?</p>
            <p id="anchor4">
              Potřebujete hlavně komunikovat, ale nikdo vám zatím nebyl schopen odhalit, co je opravdu důležité a co podružné?
            </p>
            {!detailsExpandedA && (
              <EFButton
                style={{ paddingLeft: 12, paddingRight: 12 }}
                element="span"
                onClick={() => setDetailsExpandedA(true)}
                label="Číst dál"
              />
            )}
            <Expand open={detailsExpandedA}>
              <p>Nerozumíte, proč má angličtina tolik časů tam, kde má čeština jen pár?</p>
              <p>Nemůžete se vyznat v tom, proč má zdánlivě více výrazů pro jeden v naší mateřštině?</p>
              <p>
                Chcete zažít pocit úspěchu při komunikaci v angličtině, dosáhnout svých cílů, např. složit maturitu, přijímací
                nebo mezinárodní zkoušku?
              </p>
              <p>
                Hlavně se cítit mnohem jistěji v cizině, dokonce si troufnout telefonovat se zahraničními kolegy nebo si jen
                vychutnat film v původním znění?
              </p>
              <p>Potřebujete si jednou pro vždy upevnit a efektivně rozvinout svou obecnou angličtinu? </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDetailsExpandedA(false); // @ts-ignore
                  window.scrollTo({
                    top: document.getElementById("anchor4")?.offsetTop,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <u>Skrýt text</u>
              </p>
            </Expand>
            <p>
              Pokud je vaše odpověď na většinu otázek kladná, vyzkoušejte moje skupinové kurzy pro veřejnost. Koneckonců,
              podobně jako u oblečení dnes dáváme přednost konfekci před oděvy šitými na míru, tak i pro mnoho aktivních a
              flexibilních lidí představují skupinové kurzy lákavou a dostupnou příležitost jazykového vzdělávání.
            </p>
            <Hidden lgUp>
              <div className={classes.singleRouterImage} style={{ backgroundImage: "url(/img/publicTution.jpg)" }}>
                <div className={classes.routerImageText}>Skupinové kurzy pro veřejnost</div>
              </div>
            </Hidden>
          </Grid>
          <Grid item xs={12} lg={6}>
            <p>
              Jste firma, která přichází do kontaktu se zahraničními klienty a očekává se od vás komunikace v anglickém jazyce
              na úrovni?
            </p>
            <p>Zápasí vaši zaměstnanci s nedostatečným porozuměním telefonních hovorů, dokumentů nebo emailů v angličtině? </p>
            <p>Dělá jim problém připravit si odbornou prezentaci v cizím jazyce?</p>
            <p id="anchor2">Nejsou schopni jen tak nezávazně společensky konverzovat při osobním setkání? </p>
            {!detailsExpandedB && (
              <EFButton
                style={{ paddingLeft: 12, paddingRight: 12 }}
                element="span"
                onClick={() => setDetailsExpandedB(true)}
                label="Číst dál"
              />
            )}
            <Expand open={detailsExpandedB}>
              <p>
                Nemohou se rychle a kvalifikovaně vymáčknout na obchodním jednání nebo si nejsou jisti, jak komunikovat se svými
                klienty anglicky v dané společenské situaci?
              </p>
              <p>
                Potřebují zaměřit rozvoj slovní zásoby na konkrétní obor jako např. obchod, účetnictví, IT, technika, veřejná
                správa apod.?
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDetailsExpandedB(false); // @ts-ignore
                  window.scrollTo({
                    top: document.getElementById("anchor2")?.offsetTop,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <u>Skrýt text</u>
              </p>
            </Expand>
            <p>Jste jednotlivec nebo malá skupina na přibližně stejné úrovni a nevyhovuje vám skupinový kurz? </p>
            <p id="anchor3">
              Plánujete dovolenou v anglicky mluvící zemi a chtěli byste se rozmluvit na svá preferovaná témata a při tom se
              učit svým vlastním tempem?{" "}
            </p>
            {!detailsExpandedC && (
              <EFButton
                style={{ paddingLeft: 12, paddingRight: 12 }}
                element="span"
                onClick={() => setDetailsExpandedC(true)}
                label="Číst dál"
              />
            )}
            <Expand open={detailsExpandedC}>
              <p>
                Nebo se budete ucházet o zaměstnání na pozici, v níž budete nuceni každodenně hovořit anglicky, a potřebujete
                pomoci s přípravou na přijímací pohovor?
              </p>
              <p>
                Nebo snad plánujete skládat mezinárodně uznávanou zkoušku Cambridge English (např. PET, FCE či CAE) a sami si na
                to netroufáte?
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setDetailsExpandedC(false); // @ts-ignore
                  window.scrollTo({
                    top: document.getElementById("anchor3")?.offsetTop,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                <u>Skrýt text</u>
              </p>
            </Expand>
            <p>
              Ať je váš důvod pro zlepšení angličtiny jakýkoli, jsem tady, abych vám pomohl. Nabízím kurzy šité na míru pro
              jednoho nebo více studentů na podobné úrovni, jejichž společným rysem je maximální obsahová a organizační
              flexibilita.
            </p>
            <Hidden lgUp>
              <div className={classes.singleRouterImage} style={{ backgroundImage: "url(/img/businessTution.jpg)" }}>
                <div className={classes.routerImageText}>Firemní a individuální kurzy</div>
              </div>
            </Hidden>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        <Hidden mdDown>
          <div className={classes.router}>
            <div className={classes.imageRootLeft} onClick={() => history.push("/publicTeaching")}>
              <div className={classes.imageLeft}></div>
              <div className={classes.routerImageText}>Skupinové kurzy pro veřejnost</div>
            </div>
            <div className={classes.imageRootRight} onClick={() => history.push("/companyPrivateTeaching")}>
              <div className={classes.imageRight}></div>
              <div className={classes.routerImageText} style={{ left: "initial", right: 120 }}>
                Firemní a individuální kurzy
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Hidden>
      </Container>
    </div>
  );
}
