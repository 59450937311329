import React, { useState } from "react";
import { Container, createStyles, Grid, Hidden, makeStyles, Theme } from "@material-ui/core";
import data from "../../Data/Home/WhyToLearnWithMe.json";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    reasonCard: {
      borderRadius: 8,
      [theme.breakpoints.up("md")]: {
        width: "90%",
        marginLeft: "5%",
      },
      backgroundColor: "#FDF1C8",
      position: "relative",
      minHeight: "100%",
    },
    reasonContainer: {
      cursor: "pointer",
      "&:hover": {
        opacity: "80%",
      },
    },
    reasonTitle: {
      color: "#122E5C",
      fontFamily: "Ludicrous, Roboto, sans-serif",
      fontSize: "1.8rem",
      textAlign: "center",
    },
    reasonSubtitle: {
      color: "#122E5C",
      fontFamily: "Dosis, Roboto, sans-serif",
      fontSize: "0.8rem",
      textAlign: "center",
    },
    reasonText: {
      [theme.breakpoints.up("md")]: {
        padding: 32,
        borderTop: "2px solid #122E5C",
        borderRadius: 12,
      },
    },
    reasonTextTitle: {
      color: "#155dc4",
      fontFamily: "Roboto, sans-serif",
      fontSize: "1.2rem",
    },
    reasonCardTail: {
      backgroundColor: "#122E5C",
      width: 2,
      left: "50%",
      bottom: -18,
      height: 18,
      position: "absolute",
    },
  })
);

export default function WhyToLearnWithMe() {
  const classes = useStyles();

  const [selectedReason, setSelectedReason] = useState(1);

  return (
    <Container maxWidth="lg">
      <h2 id="procseucit">PROČ SE UČIT SE MNOU</h2>
      <br />
      <Grid container spacing={3}>
        {data.map((d, i) => (
          <Grid key={i} item xs={12} md={4} onClick={() => setSelectedReason(i)} className={classes.reasonContainer}>
            <div className={classes.reasonCard} style={selectedReason === i ? { backgroundColor: "#122E5C" } : {}}>
              <div style={{ padding: 16, paddingRight: 0 }}>
                <div className={classes.reasonTitle} style={selectedReason === i ? { color: "white" } : {}}>
                  {d.title}
                </div>
                <div className={classes.reasonSubtitle} style={selectedReason === i ? { color: "white" } : {}}>
                  {d.subtitle}
                </div>
              </div>
              <Hidden smDown>{selectedReason === i && <div className={classes.reasonCardTail}></div>}</Hidden>
            </div>
          </Grid>
        ))}
      </Grid>
      <br />
      <div className={classes.reasonText}>
        {data[selectedReason].text.map((t, i) => (
          <p key={i} dangerouslySetInnerHTML={{ __html: t }} />
        ))}
      </div>
    </Container>
  );
}
