import React from "react";
import { Container, createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router";
import Flex from "../Components/Flex";
import { Email, Facebook, LinkedIn, Phone, Twitter, WhatsApp, YouTube } from "@material-ui/icons";
import useWindowDimensions from "../Hooks/GetWindowDimensions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#122E5C",
      padding: 32,
      color: "white",
    },
    list: {
      listStyle: "none",
      padding: 0,
      "& li": {
        color: "white",
      },
    },
    pointer: {
      cursor: "pointer",
    },
    logo: {
      height: 67,
      marginTop: 16,
      marginBottom: 16,
    },
  })
);

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();

  const { width } = useWindowDimensions();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Flex spaceBetween direction={width < 960 ? "column" : "row"}>
          <div>
            <ul className={classes.list}>
              <img className={classes.logo} onClick={() => history.push("/")} src="/img/footerLogo.svg" alt="logo" />
              <li>Jazykové studio</li>
              <li>se zaměřením na výuku angličtiny </li>
              <li>distanční i prezenční kurzy</li>
              <li>pro veřejnost, firmy i jednotlivce </li>
            </ul>
          </div>
          <div>
            <h3 style={{ color: "#FDF1C8" }}>Kontakt</h3>
            <ul className={classes.list}>
              <li style={{ display: "flex", alignItems: "center", paddingBottom: 8 }}>
                <Email />
                &nbsp;&nbsp;info@englishfit.cz
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <Phone />
                &nbsp;&nbsp;
                <WhatsApp />
                &nbsp;&nbsp;+420 608 821 819
              </li>
              <li style={{ paddingTop: 6 }}>
                <Tooltip title="Sleduj nás na Facebooku">
                  <Facebook style={{ cursor: "pointer" }} onClick={() => window.open("https://www.facebook.com/englishfit.cz", "_blank")} />
                </Tooltip>
                &nbsp;&nbsp;
                <Tooltip title="Sleduj nás na YouTube">
                  <YouTube style={{ cursor: "pointer" }} onClick={() => window.open("https://www.youtube.com/channel/UCzlxNVbp3yONBXjbyy2KxNw", "_blank")} />
                </Tooltip>
                &nbsp;&nbsp;
                <Tooltip title="Sleduj nás na LinkedInu">
                  <LinkedIn style={{ cursor: "pointer" }} onClick={() => window.open("https://www.linkedin.com/company/englishfit", "_blank")} />
                </Tooltip>
                &nbsp;&nbsp;
                <Tooltip title="Sleduj nás na Twitteru">
                  <Twitter style={{ cursor: "pointer" }} onClick={() => window.open("https://twitter.com/englishfit_cz", "_blank")} />
                </Tooltip>
              </li>
              <li>Pro komunikaci lze rovněž</li>
              <li>
                použít formulář{" "}
                <u className={classes.pointer} onClick={() => history.push("/contact#contactForm")}>
                  Napište mi
                </u>
                .
              </li>
            </ul>
          </div>
          <div>
            <h3 style={{ color: "#FDF1C8" }}>Mohlo by se hodit</h3>
            <ul className={classes.list}>
              <li onClick={() => history.push("/conditions")}>
                <u className={classes.pointer}>Obchodní podmínky</u>
              </li>
              <li onClick={() => history.push("/privacyPolicy")}>
                <u className={classes.pointer}>Zpracování osobních údajů</u>
              </li>
            </ul>
            <br />
            <ul className={classes.list}>
              <li
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                }
              >
                <u className={classes.pointer}>Zpět nahoru</u>
              </li>
            </ul>
          </div>
        </Flex>
      </Container>
    </div>
  );
}
