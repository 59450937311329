import React, { ReactNode } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: "flex" },
  })
);

interface IProps {
  children: ReactNode;
  spaceEvenly?: boolean;
  spaceBetween?: boolean;
  center?: boolean;
  style?: React.CSSProperties;
  direction?: "row" | "column";
}

export default function Flex({ children, spaceEvenly, spaceBetween, direction, style, center }: IProps) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        ...style,
        justifyContent: spaceEvenly ? "space-evenly" : spaceBetween ? "space-between" : center ? "center" : "initial",
        flexDirection: direction || "row",
      }}
    >
      {children}
    </div>
  );
}
