import React from "react";
import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 32,
      paddingBottom: 64,
      backgroundColor: "white",
    },
  })
);

export default function Levels() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <h2 id="urovne">JAZYKOVÉ ÚROVNĚ</h2>
        <p>
          Téměř pokaždé, když se ptám nového studenta při vstupním pohovoru na jeho úroveň znalosti angličtiny, slyším jednu ze
          dvou odpovědí: jsem začátečník, resp. pokročilý. To zajisté nestačí. Všeobecně se v oblasti jazykového vzdělávání
          používají dva typy klasifikace. Na jedné straně tradiční britská klasifikace a na straně druhé poměrně nová evropská
          klasifikace dle Společného evropského referenčního rámce pro jazyky (SERR), anglicky Common European Framework of
          Reference for Languages (CEFR).
        </p>
        <p>
          Vědět, na jaké úrovni angličtiny se nalézáte, je opravdu zásadní. Tento údaj uvádíte např. v životopise nebo v různých
          dotaznících. Navíc je dobré mít svoji úroveň pokročilosti na mysli při výběru vhodných studijních materiálů a učebnic.
          Níže uvádím úrovně pokročilosti ve Společném evropském referenčním rámci (SERR) ve srovnání s tradiční britskou a
          českou klasifikací se stručným popisem požadovaných dovedností.
        </p>
        <h4 style={{ marginBlockStart: 0 }}>A0 / Beginner / Úplný začátečník ÚZ </h4>
        <p style={{ marginBlockStart: 0 }}>
          Má jen malou nebo nulovou znalost cizího jazyka. Teprve se učí slovní zásobu, základy výslovnosti a gramatiky.
        </p>
        <h4 style={{ marginBlockStart: 0 }}>A1 / Elementary / Nepravý začátečník NZ </h4>
        <p style={{ marginBlockStart: 0 }}>
          Slabší pasivní znalost cizího jazyka. Student by měl znát základní slovní zásobu i základy gramatiky. Je schopen
          pasivně porozumět základním frázím a některým částem jednoduchého mluveného a psaného projevu, který se týká jeho
          osoby a každodenního života, např. rodiny, koníčků, jídla apod. Nepravý začátečník dokáže velmi jednoduchými větami
          konverzovat a odpovídat na jednoduché otázky, pokud na něj okolí mluví pomalu a trpělivě.
        </p>
        <h4 style={{ marginBlockStart: 0 }}>A2 / Pre-Intermediate / Mírně pokročilý MP </h4>
        <p style={{ marginBlockStart: 0 }}>
          Pasivní znalost cizího jazyka. Student rozumí jasnému mluvenému i psanému projevu, zejména okruhu témat, která jsou mu
          blízká. Jeho slovní zásoba se konkretizuje a znalosti anglické gramatiky se prohlubují. Zvládá odpovídat na otázky a
          vést jednodušší, ale smysluplný rozhovor.
        </p>
        <h4 style={{ marginBlockStart: 0 }}>B1 / Intermediate / Středně pokročilý SP</h4>
        <p style={{ marginBlockStart: 0 }}>
          Komunikativní znalost cizího jazyka. Student rozumí bez problémů mluvenému i psanému projevu, dokáže hovořit o různých
          tématech i popsat abstraktní pojmy. Mluvený projev je plynulý s větší slovní zásobou a znalostí běžných frází. Dokáže
          spontánně odpovídat a popsat své plány či zážitky, umí rozeznat formální a neformální jazyk.
        </p>
        <h4 style={{ marginBlockStart: 0 }}>B2 / Upper-Intermediate / Více pokročilý VP </h4>
        <p style={{ marginBlockStart: 0 }}>
          Dobrá komunikativní znalost cizího jazyka. Student dokáže plynule konverzovat i na odbornější témata, vyjádřit svůj
          názor, vysvětlit a zdůvodnit svá stanoviska. Chápe složitější slovní spojení, idiomy a dokáže rozlišit přízvuky. Je
          schopen napsat složitější souvislý text, například esej nebo úvahu.
        </p>
        <h4 style={{ marginBlockStart: 0 }}>C1 / Advanced / Pokročilý P </h4>
        <p style={{ marginBlockStart: 0 }}>
          Velmi dobrá komunikativní znalost cizího jazyka. Student dokáže porozumět odborným textům, rozeznává implicitní
          významy. Komunikuje plynule a rychle, své myšlenky vyjadřuje pružně a smysluplně. Psaný projev je členěn přehledně,
          zahrnuje i rozsáhlejší texty na odborná témata.
        </p>
        <h4 style={{ marginBlockStart: 0 }}>C2 / Proficient / Jazykově způsobilý, Expert E </h4>
        <p style={{ marginBlockStart: 0 }}>
          Výborná komunikativní znalost cizího jazyka. Student je schopen bez potíží rozumět psanému i mluvenému projevu. Jeho
          vyjadřování je plynulé, srozumitelné, konkrétní a spontánní. Ovládá fráze, odborné termíny i slang. Umí vyjádřit své
          názory, diskutovat na libovolné téma bez gramatických chyb a s použitím správné výslovnosti. Na první pohled není
          patrný rozdíl mezi tímto a rodilým mluvčím.
        </p>
      </Container>
    </div>
  );
}
