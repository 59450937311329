import React from "react";
import { Container, createStyles, Grid, Hidden, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "#FFFBEE",
      paddingTop: 64,
      paddingBottom: 64,
    },
    illustrationImage: {
      width: "100%",
      height: "100%",
      backgroundImage: "url(/img/crossing.jpg)",
      backgroundPosition: "center",
    },
  })
);

export default function Content() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <h2 id="obsah">OBSAH KURZU</h2>
        <br />
        <br />
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <p>
              Jako nový student absolvujete bezplatný vstupní test a osobní pohovor, při kterém probereme vaše předchozí
              studijní zkušenosti a společně stanovíme vaši současnou úroveň znalostí včetně vašich silných a slabých stránek.
              Zformulujeme vaše vzdělávací cíle, tj. v jakých kontextech byste chtěli jazyk uplatnit, a navrhneme způsob, jak
              těchto cílů dosáhnout.
            </p>
            <p>
              První částí kurzu by mělo být utvrzení a rozšíření gramatických struktur ve formě jasného výkladu a účinného
              nácviku, česko-anglického drilu, který má spád a nenechá vás ani na chvíli vydechnout. Smyslem je zautomatizovat
              tvorbu věty do té míry, že student podobně jako ve své mateřštině, tak ani v cizím jazyce nesmí přemýšlet nad tím,
              jak něco říct, ale právě jen nad tím, co chce říct.
            </p>
            <p>
              Poté, co jsou takto upevněny základy stavby jazyka na dané pokročilosti, přistupujeme k budování a rozvoji
              specifických dovedností a slovní zásoby, přičemž veškerá konverzace je dále vedena pouze v angličtině.
            </p>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "calc(24px + 1em)" }}>
            <Hidden smDown>
              <div className={classes.illustrationImage}></div>
            </Hidden>
            <Hidden mdUp>
              <img style={{ width: "100%" }} src="/img/crossing.jpg" alt="" />
            </Hidden>
          </Grid>
          <Grid item xs={12}>
            <p>
              Jen vy rozhodujete, jaký formát konverzačních lekcí vám vyhovuje nejvíce. Může mít podobu jakékoli kombinace
              následujících bodů:
            </p>
            <ul style={{ paddingLeft: 20, textAlign: "justify", paddingRight: "1em" }}>
              <li>
                Práce s vybranými kapitolami renomovaných anglických učebnic, které plní vaše vzdělávací cíle při rozvoji všech
                dovedností, tj. mluvení, psaní, poslechu i četby.
              </li>
              <li>
                Čtení zjednodušených i autentických textů nahlas pod mým vedením se zaměřením na správnou výslovnost, celkové
                porozumění čtenému obsahu, detailní identifikaci a učení relevantní slovní zásoby z textu.
              </li>
              <li>
                Poslech a sledování zjednodušených i autentických audio či video nahrávek pod mým vedením se zaměřením na
                celkové porozumění, následnou konverzaci a v závěru detailní identifikaci a učení relevantní slovní zásoby z
                přepisu nahrávky.
              </li>
              <li>
                Otevřená nebo volná konverzace na jakékoli téma, které vás zajímá. Povzbudím plynulost konverzace a nebudu
                přerušovat mluvený projev opravou dílčích chyb, s výjimkou těch závažnějších, bránících porozumění. Ale vždy po
                skončení diskuze na dané téma proberu všechny zásadní nedostatky v gramatice nebo ve výslovnosti a navrhnu
                způsoby jejich odstranění.
              </li>
              <li>
                Sestavení a procvičení slovní zásoby a výrazů pravidelně používaných v dané situaci, ve které byste chtěli
                rozvíjet své znalosti anglického jazyka.
              </li>
            </ul>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
