import React, { useState } from "react";
import { createStyles, makeStyles, Theme, Container, Grid, Hidden } from "@material-ui/core";
import EFButton from "../../Components/EFButton";
import Expand from "react-expand-animated";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 32,
      paddingBottom: 32,
      backgroundColor: "#FFFBEE",
    },
    profile: {
      borderRadius: 24,
      width: "90%",
      marginLeft: "5%",
    },
    name: {
      color: "#122e5c",
      fontFamily: "Roboto, sans-serif",
      fontSize: "0.86rem",
      width: "100%",
      textAlign: "center",
    },
    schools: {
      color: "#122e5c",
      fontFamily: "Roboto, sans-serif",
      "& li": {
        fontSize: "0.86rem",
      },
      paddingLeft: 0,
      textAlign: "center",
      listStyle: "none",
      listStyleImage: "url(img/iphone.png)",
    },
    readMore: {
      color: "white",
      fontFamily: "Roboto, sans-serif",
      cursor: "pointer",
      backgroundColor: "#122E5C",
      padding: "2px 12px",
      borderRadius: 4,
      display: "inline-block",
      "&:hover": {
        backgroundColor: "#394E6F",
      },
    },
    aboutMeBlock: {
      paddingLeft: 82,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
    myPathBlock: {
      paddingLeft: 32,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
    },
  })
);

export default function AboutMe() {
  const classes = useStyles();
  const location = useLocation();

  const [howIGotToEnglishExpanded, setHowIGotToEnglishExpanded] = useState(location.hash.startsWith("#mojecesta"));

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={4} justifyContent="center" style={{ textAlign: "center", width: "100%" }}>
            <br />
            <br />
            <img className={classes.profile} src="/img/profile.png" alt="Profile" />
            <br />
            <br />
            <div className={classes.name}>Relevantní jazyková kvalifikace</div>
            <ul className={classes.schools}>
              <li>jednooborové studium anglického jazyka, FF MU Brno</li>
              <li>všeobecná státní zkouška z anglického jazyka</li>
              <li>roční kurz obchodní angličtiny v Brno English Centre</li>
              <li>semináře a kurzy z oblasti metodiky výuky anglického jazyka</li>
              <li>pracovně-poznávací pobyty ve Velké Británii, USA, JAR</li>
            </ul>
          </Grid>
          <Grid item md={8} className={classes.aboutMeBlock}>
            <h2 id="omne">O MNĚ</h2>
            <p>
              Dobrý den, jmenuji se Marek Chocholatý a vítám vás na stránkách jazykového studia EnglishFit. Již od začátku 90.
              let vyučuji dospívající a dospělé anglický jazyk od začátečníků po pokročilé, jednotlivce i skupiny.
            </p>
            <p>
              Velkou část své profesní dráhy jsem zasvětil spolupráci s Nepustilovou jazykovou školou, kde jsem vedl skupinové
              kurzy, školil nové lektory, tvořil koncepční a výukové materiály.
            </p>
            <p>
              K těmto aktivitám orientovaných na širokou veřejnost a všeobecnou angličtinu jsem jako pojistku proti profesnímu
              vyhoření přidal na přelomu tisíciletí výuku jednotlivců a malých skupin spojených danou profesí, což mi umožnilo
              cílit na užší okruh témat a dovedností a zvýšit tak efektivitu celého procesu. Postupně jsem si vytvořil ucelený
              systém a široké portfolio podkladů jak v papírové, tak elektronické podobě, o kterém mohu s jistotou říct, že
              spolehlivě funguje.
            </p>

            <p>
              Je to asi jako ve všem. Když děláte věci pořádně, tak se vám to dříve nebo později v dobrém vrátí. Spokojený či
              nadšený zákazník je ta nejlepší reklama. Pokaždé mě potěší, když mě osloví nový zájemce o výuku na doporučení
              někoho z mých bývalých studentů.
            </p>
            <p id="anchor1">
              Za dobu svého působení jsem vedl kurzy v řadě firem, např. Český Telecom, ČNB pobočka Brno, SGS Czech Republic,
              Urban Transporte, Kooperativa pojišťovna, ZFP akademie, Triglav pojišťovna, Holiday Inn, Hošek Motor, Lohmann
              Rausher, PZK, S&K, Apex Central Europe, Dopravní stavby Brno, OHL ŽS, voestalpine Profilform, SAKO Brno, Ústřední
              kontrolní a zkušební ústav zemědělský, Hippoinvest a další.
            </p>
            {!howIGotToEnglishExpanded && (
              <>
                <br />
                <EFButton
                  id="mojecesta"
                  onClick={() => setHowIGotToEnglishExpanded(true)}
                  style={{ minWidth: 120, display: "inline-block", fontFamily: "Dosis, Roboto, sans-serif" }}
                  label="MOJE CESTA"
                />
              </>
            )}
          </Grid>
        </Grid>

        <br />
        <br />
        <Expand open={howIGotToEnglishExpanded}>
          <h2 id="mojecesta" className={classes.myPathBlock}>
            MOJE CESTA
          </h2>
          <Grid container spacing={6} className={classes.myPathBlock}>
            <Grid item md={6}>
              <h4>Jak jsem k angličtině přišel a kde se ve mě vzala ta drzost ji učit</h4>
              <br />
              <p>
                K angličtině mě v mládí přivedly písničky, jejichž textu jsem chtěl rozumět. O něco později pak přišla touha
                cestovat a poznat jinou kulturu. Když padla železná opona, sehnal jsem si v r. 1990 pracovně-poznávací pobyt v
                Británii, kde jsem poznal řadu rodilých mluvčích, které jsem potom provázel po Česku, resp. pro ně zajistil
                podobný pobyt u nás. Následovalo studium jednooborové angličtiny na FF MU provázené dalšími prázdninovými pobyty
                v Británii. Jak rostlo mé sebevědomí ve zvládnutí jazyka, začal jsem kolem sebe vnímat zřetelnou poptávku po
                poctivé a efektivní výuce ze strany veřejnosti. Proč tedy nespojit příjemné s užitečným?
              </p>
              <p>
                Učit angličtinu jsem začal již počátkem 90. let během studia na vysoké škole, zpočátku za zvědavosti coby
                brigádu, ale postupně jsem zjistil, že mě to neuvěřitelně baví a naplňuje. Po několika krátkých anabázích v
                různých jazykových školách jsem zkusil na podzim r. 1992 také výuku v kurzech Dr. Nepustila, soustřeďující se na
                rychlé zvládnutí gramatiky a přechod na práci s neupravenými texty.
              </p>
              <Hidden smDown>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setHowIGotToEnglishExpanded(false);
                    // @ts-ignore
                    window.scrollTo({
                      top: document.getElementById("anchor1")?.offsetTop,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <u>Skrýt text</u>
                </p>
              </Hidden>
            </Grid>
            <Grid item md={6}>
              <h4>Životní setkání s Vladimírem Nepustilem a jeho metodous</h4>
              <br />
              <p>
                Již záhy jsem objevil, že dokážu motivovat studenty k rychlému pokroku ve věcech, které vůbec neznali nebo v
                nich dlouho jen tápali. V té době existovaly v jazykovce pouze tři pokročilosti gramatických kurzů a jedna v
                konverzačních, učebnice byla velmi stručná a pokrývala jen část učiva, což jsem bral jako výzvu. Na popud
                Vladimíra Nepustila jsem sepsal učebnici angličtiny pro středně a více pokročilé a taky pod jeho vedením
                účinkoval ve videokurzu angličtiny pro úplné a chronické začátečníky a mírně pokročilé. Myslím, že jsme byli
                výbornými přáteli a pracovními partnery. I po jeho nečekaném úmrtí v r. 1995 jsem pokračoval ve spolupráci s
                kolegy v metodickém rozvoji anglické sekce jazykové školy tvorbou učebních plánů, testů a školením nových
                lektorů. Začátkem milénia jsem zásadně rozšířil učebnici pro začátečníky a mírně pokročilé včetně zcela nových
                pasáží výkladu a cvičení. Jak masivně rostl počet studentů, bylo třeba ještě více propojit učební plány
                jazykovky s mezinárodními učebnicemi, což vedlo k rozšíření na pět gramatických a tři konverzační úrovně.
              </p>
              <Hidden mdUp>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setHowIGotToEnglishExpanded(false);
                    // @ts-ignore
                    window.scrollTo({
                      top: document.getElementById("anchor1")?.offsetTop,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                >
                  <u>Skrýt text</u>
                </p>
              </Hidden>
            </Grid>
          </Grid>
        </Expand>
      </Container>
    </div>
  );
}
