import React, { useEffect, useState } from "react";
import {
  Container,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
// import EFButton from "../../Components/EFButton";
import LevelsSnake from "../../Components/LevelsSnake";
import { Course, Courses } from "../../Types/courses";
import { separateThousands } from "../../Utils/Common";
import EFButton from "../../Components/EFButton";
import Flex from "../../Components/Flex";
import useWindowDimensions from "../../Hooks/GetWindowDimensions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFFAF6",
      paddingTop: 32,
      paddingBottom: 32,
    },
    currentLevelContainer: {
      borderRadius: 12,
      border: "2px solid #122e5c",
      padding: 32,
      minHeight: "100%",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        minHeight: "calc(100% - 56px)",
        marginBottom: 24,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 390,
        display: "inline-block",
      },
    },
    tableCell: {
      fontFamily: "Dosis, Roboto, sans-serif",
      color: "#122e5c",
      fontSize: "1.1rem",
    },
    signUpButton: {
      fontFamily: "Dosis, Roboto, sans-serif",
      color: "#122e5c",
      cursor: "pointer",
      padding: 2,
      backgroundColor: "#fda690",
      width: 108,
      textAlign: "center",
      borderRadius: 4,
      fontWeight: "bold",
      display: "inline-block",
      marginBottom: 6,
    },
    reasonCardTail: {
      backgroundColor: "#122E5C",
      width: 1,
      left: "50%",
      bottom: -32,
      height: 32,
      position: "absolute",
    },
    topBorder: {
      [theme.breakpoints.up("sm")]: {
        borderTop: "1px solid #122e5c",
        borderRadius: 12,
      },
    },
    rightRectTextContainer: {
      [theme.breakpoints.up("lg")]: {
        maxWidth: 390,
        display: "inline-block",
      },
    },
  })
);

interface IProps {
  info?: Courses;
  drawCourses?: boolean;
  signUp?: (level: string, course: string) => void;
}

export default function Levels({ info, drawCourses, signUp }: IProps) {
  const classes = useStyles();
  const [selectedCourse, setSelectedCourse] = useState("A0Gra");
  const [selectedCourseType, setSelectedCourseType] = useState<"Cond" | "Norm" | "all">("Norm");
  const [counter, setCounter] = useState(0);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const id = setInterval(() => setCounter(counter + 1), 100000);
    return () => clearInterval(id);
  }, [counter]);

  const getCoursePrice = (course: Course, type: string) => {
    const twoWeeksMillis = 1209600000;
    const fourWeeksMillis = 2419200000;
    const start = new Date(course.start);
    const today = new Date();
    const diffTime = start.getTime() - today.getTime();
    if (diffTime < 0) return 0;
    const priceCategory = info?.prices[type.toUpperCase() + course.priceCategory];
    if (diffTime > fourWeeksMillis) {
      return priceCategory?.fourWeeks;
    }
    if (diffTime > twoWeeksMillis) {
      return priceCategory?.twoWeeks;
    }
    return priceCategory?.normal;
  };

  const getCourseBasePrice = (course: Course, type: string) => {
    const priceCategory = info?.prices[type.toUpperCase() + course.priceCategory];
    return priceCategory?.normal;
  };

  const getDiscountValidityTime = (course: Course, counter: number) => {
    const twoWeeksMillis = 1209600000;
    const fourWeeksMillis = 2419200000;
    const start = new Date(course.start);
    const today = new Date();
    const diffTime = start.getTime() - today.getTime();
    if (diffTime < 0) return -1;
    if (diffTime > fourWeeksMillis) {
      return diffTime - fourWeeksMillis;
    }
    if (diffTime > twoWeeksMillis) {
      return diffTime - twoWeeksMillis;
    }
    return diffTime;
  };

  const parseMillis = (millis: number) => {
    const cd = 24 * 60 * 60 * 1000;
    const ch = 60 * 60 * 1000;
    let d = Math.floor(millis / cd);
    let h = Math.floor((millis - d * cd) / ch);
    let m = Math.round((millis - d * cd - h * ch) / 60000);
    const pad = (n: number) => (n < 10 ? "0" + n : n);
    if (m === 60) {
      h++;
      m = 0;
    }
    if (h === 24) {
      d++;
      h = 0;
    }
    return `${d}\u00a0d\u00a0${pad(h)}\u00a0h\u00a0${pad(m)}\u00a0m`;
  };

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <h2 id="kurzydleurovne">ČLENĚNÍ KURZŮ PODLE JAZYKOVÉ ÚROVNĚ</h2>
          <p>
            Níže uvádím vstupní pokročilost ve Společném evropském referenčním rámci (SERR) ve srovnání s tradiční britskou a
            českou klasifikací, a to jak do svých gramatických tak i konverzačních kurzů, které na sebe přirozeně navazují.
            Nicméně je volbou každého studenta, zda pokračuje v další úrovni oběma částmi (gramatikou i konverzací) či jen
            jednou z nich, pokud získal odpovídající znalosti v terénu nebo v jiné škole. V případě nejasností lze absolvovat
            vstupní test nebo osobní pohovor.
          </p>
          <br />
          <br />

          <Grid container spacing={6} justifyContent="center">
            <Grid item xs={12} md={6}>
              <LevelsSnake
                allCourses={info?.allCourses || []}
                courses={info?.courses || {}}
                setSelectedCourse={setSelectedCourse}
                selectedCourse={selectedCourse}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ height: width >= 960 ? 662 : "initial", padding: 20, textAlign: "center" }}>
              <div
                id="courseDescriptionBox"
                style={{ backgroundColor: info?.courses[selectedCourse].color }}
                className={classes.currentLevelContainer}
              >
                <h3 style={{ fontFamily: "Dosis, Roboto, sans-serif", fontWeight: "bold" }}>
                  {info?.courses[selectedCourse].title} {info?.courses[selectedCourse].type}
                </h3>
                <p>{info?.courses[selectedCourse].description}</p>
                <br />
                <EFButton
                  label="Nejbližší plánované kurzy"
                  style={{
                    fontFamily: "Dosis, Roboto, sans-serif",
                    width: "80%",
                    marginLeft: "10%",
                    paddingTop: 6,
                    paddingBottom: 6,
                  }}
                  onClick={() => {
                    // @ts-ignore
                    window.scrollTo({
                      top: document.getElementById("coursesTable")?.offsetTop,
                      left: 0,
                      behavior: "smooth",
                    });
                  }}
                />
              </div>
              <br />
              <br />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div
        id="coursesTable"
        style={{
          backgroundColor: info?.courses[selectedCourse].color,
          paddingTop: 32,
          paddingBottom: 64,
          borderTop: "2px solid #122e5c",
        }}
      >
        <Container maxWidth="lg">
          <p style={{ fontSize: "1.8rem" }}>
            <b>
              {info?.courses[selectedCourse].title} {info?.courses[selectedCourse].type} - nejbližší plánované kurzy:
            </b>
          </p>
          <Flex spaceBetween style={{ padding: 32 }}>
            <div style={{ position: "relative" }}>
              <EFButton
                style={{
                  ...(selectedCourseType === "Norm" ? {} : { backgroundColor: "white", color: "#122e5c" }),
                  fontFamily: "Dosis, Roboto, sans-serif",
                  maxWidth: width < 600 ? 52 : "initial",
                }}
                onClick={() => setSelectedCourseType("Norm")}
                label="řádné"
              />
              <Hidden xsDown>{selectedCourseType === "Norm" && <div className={classes.reasonCardTail}></div>}</Hidden>
            </div>
            <div style={{ position: "relative" }}>
              <EFButton
                style={{
                  ...(selectedCourseType === "Cond" ? {} : { backgroundColor: "white", color: "#122e5c" }),
                  fontFamily: "Dosis, Roboto, sans-serif",
                  maxWidth: width < 600 ? 52 : "initial",
                }}
                onClick={() => setSelectedCourseType("Cond")}
                label="kondiční"
              />
              <Hidden xsDown>{selectedCourseType === "Cond" && <div className={classes.reasonCardTail}></div>}</Hidden>
            </div>
            <div style={{ position: "relative" }}>
              <EFButton
                style={{
                  ...(selectedCourseType === "all" ? {} : { backgroundColor: "white", color: "#122e5c" }),
                  fontFamily: "Dosis, Roboto, sans-serif",
                  maxWidth: width < 600 ? 52 : "initial",
                }}
                onClick={() => setSelectedCourseType("all")}
                label="všechny"
              />
              <Hidden xsDown>{selectedCourseType === "all" && <div className={classes.reasonCardTail}></div>}</Hidden>
            </div>
          </Flex>
          <div className={classes.topBorder}>
            <Table>
              <Hidden xsDown>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>kurz</TableCell>
                    <Hidden smDown>
                      <TableCell className={classes.tableCell}>termín</TableCell>
                      <TableCell className={classes.tableCell}>rozvrh</TableCell>
                    </Hidden>
                    <Hidden mdUp>
                      <TableCell className={classes.tableCell}>Termín a rozvrh</TableCell>
                    </Hidden>
                    <TableCell className={classes.tableCell}>cena</TableCell>
                    <TableCell className={classes.tableCell}></TableCell>
                  </TableRow>
                </TableHead>
              </Hidden>
              <TableBody>
                {info?.courses[selectedCourse].courses
                  .filter((c) => selectedCourseType === "all" || c.priceCategory === selectedCourseType)
                  .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
                  .map((c, i) => (
                    <TableRow key={i}>
                      <Hidden xsDown>
                        <TableCell className={classes.tableCell}>
                          <b>
                            {info?.courses[selectedCourse].title} {info?.courses[selectedCourse].type}
                          </b>
                          <br />
                          <b>{c.name} kurz</b>
                        </TableCell>
                        <Hidden smDown>
                          <TableCell className={classes.tableCell}>{c.dates}</TableCell>
                          <TableCell className={classes.tableCell}>{c.times}</TableCell>
                        </Hidden>
                        <Hidden mdUp>
                          <TableCell className={classes.tableCell}>
                            {c.dates}
                            <br />
                            {c.times}
                          </TableCell>
                        </Hidden>
                        <TableCell style={{ minWidth: 164 }} className={classes.tableCell}>
                          {(getCourseBasePrice(c, selectedCourse.substring(2, 4)) || 0) >
                            (getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) && (
                            <>
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  textDecoration:
                                    (getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) > 0 ? "none" : "line-through",
                                }}
                              >
                                {separateThousands(getCourseBasePrice(c, selectedCourse.substring(2, 4)))} Kč
                              </span>
                              &nbsp;&nbsp; &nbsp;&nbsp;
                            </>
                          )}
                          {(getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) > 0 && (
                            <b>{separateThousands(getCoursePrice(c, selectedCourse.substring(2, 4)))} Kč</b>
                          )}
                          {((getCourseBasePrice(c, selectedCourse.substring(2, 4)) || 0) >
                            (getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) && (
                            <>
                              <br />
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                {(getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) > 0 ? (
                                  <>sleva končí za: {parseMillis(getDiscountValidityTime(c, counter))}</>
                                ) : (
                                  <>
                                    aktuální cena je určena proporčně <br />
                                    podle počtu absolvovaných bloků
                                  </>
                                )}
                              </span>
                            </>
                          )) || <>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</>}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <div
                            className={classes.signUpButton}
                            style={{
                              backgroundColor:
                                c.noSignUp === true
                                  ? "#808080"
                                  : (getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) > 0
                                  ? "#fda690"
                                  : "#fda690",
                              color: c.noSignUp === true ? "white" : "#122e5c",
                              cursor: c.noSignUp === true ? "not-allowed" : "pointer",
                            }}
                            onClick={() =>
                              c.noSignUp === true
                                ? {}
                                : (signUp as (level: string, course: string) => void)(selectedCourse, c.code)
                            }
                          >
                            Přihlásit se
                          </div>
                          {c.noSignUp === true ? (
                            <>
                              <br />
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                }}
                              >
                                <>do kurzu se nelze přihlásit</>
                              </span>
                            </>
                          ) : (
                            (getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) <= 0 && (
                              <>
                                <br />
                                <span
                                  style={{
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  <>kurz běží, přihlásit se za aktuální cenu</>
                                </span>
                              </>
                            )
                          )}
                        </TableCell>
                      </Hidden>
                      <Hidden smUp>
                        <TableCell className={classes.tableCell}>
                          <b>
                            {info?.courses[selectedCourse].title} {info?.courses[selectedCourse].type} {c.name} kurz
                          </b>
                          <br />
                          Termín: {c.dates}
                          <br />
                          Rozvrh: {c.times}
                          <br />
                          Cena: <b>{separateThousands(getCoursePrice(c, selectedCourse.substring(2, 4)))} Kč</b>{" "}
                          {(getCourseBasePrice(c, selectedCourse.substring(2, 4)) || 0) >
                            (getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) && (
                            <>
                              <span style={{ fontSize: "0.8rem" }}>
                                {separateThousands(getCourseBasePrice(c, selectedCourse.substring(2, 4)))} Kč
                              </span>
                            </>
                          )}
                          {(getCourseBasePrice(c, selectedCourse.substring(2, 4)) || 0) >
                            (getCoursePrice(c, selectedCourse.substring(2, 4)) || 0) && (
                            <>
                              <br />
                              <span style={{ fontSize: "0.8rem" }}>
                                sleva končí za: {parseMillis(getDiscountValidityTime(c, counter))}
                              </span>
                            </>
                          )}
                          <br />
                          <br />
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <div
                              className={classes.signUpButton}
                              onClick={() => (signUp as (level: string, course: string) => void)(selectedCourse, c.code)}
                            >
                              Přihlásit se
                            </div>
                          </div>
                        </TableCell>
                      </Hidden>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </Container>
      </div>
    </>
  );
}
