import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./Layout/Layout";
import CompanyPrivateTeaching from "./Pages/CompanyPrivateTeaching/CompanyPrivateTeaching";
import Contact from "./Pages/Contact/Contact";
import Home from "./Pages/Home/Home";
import Conditions from "./Pages/Others/Conditions";
import PrivacyPolicy from "./Pages/Others/PrivacyPolicy";
import PublicTeaching from "./Pages/PublicTeaching/PublicTeaching";
import StudentZone from "./Pages/StudentZone/StudentZone";
import Teaching from "./Pages/Teaching/Teaching";

import "./styles/index.css";

ReactDOM.render(
  <BrowserRouter>
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/teaching" exact>
          <Teaching />
        </Route>
        <Route path="/companyPrivateTeaching" exact>
          <CompanyPrivateTeaching />
        </Route>
        <Route path="/publicTeaching" exact>
          <PublicTeaching />
        </Route>
        <Route path="/studentZone" exact>
          <StudentZone />
        </Route>
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path="/conditions" exact>
          <Conditions />
        </Route>
        <Route path="/privacyPolicy" exact>
          <PrivacyPolicy />
        </Route>
        <Route path="/uvod" exact>
          <Home />
        </Route>
        <Route path="/vyuka" exact>
          <Teaching />
        </Route>
        <Route path="/firmy" exact>
          <CompanyPrivateTeaching />
        </Route>
        <Route path="/verejnost" exact>
          <PublicTeaching />
        </Route>
        <Route path="/studenti" exact>
          <StudentZone />
        </Route>
        <Route path="/kontakt" exact>
          <Contact />
        </Route>
      </Switch>
    </Layout>
  </BrowserRouter>,
  document.getElementById("root")
);
