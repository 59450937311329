import React from "react";
import { Grid, Container, createStyles, makeStyles, Theme, Hidden } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      width: "100%",
      paddingTop: 32,
      paddingBottom: 64,
    },
    bgLeft: {
      backgroundColor: "#FFFBEE",
      zIndex: -1,
      left: 0,
      top: 0,
      bottom: 0,
      position: "absolute",
      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    bgRight: {
      right: 0,
      top: 0,
      zIndex: -1,
      bottom: 0,
      background: "#FBF6E5",
      position: "absolute",
      width: "50%",
    },
  })
);

export default function Methodics() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.bgLeft}></div>
      <Hidden smDown>
        <div className={classes.bgRight}></div>
      </Hidden>

      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <h2 id="metoda">METODA</h2>
            <p>
              Kombinuji efektivní vybudování nebo oprášení gramatických základů v dané pokročilosti a plynulý přechod k četbě
              textů, poslechu nebo sledování nahrávek a především konverzaci s důrazem na vědomý rozvoj slovní zásoby na
              intuitivním pozadí dříve vštípené gramatiky.
            </p>
            <p>
              V <b>gramatické části</b> používám hojně vizuální podporu ve formě souhrnných přehledů, tabulek a schémat, které
              se česko-anglickým drilem na nejběžnější slovní zásobě postupně, ale nesmazateně "vypalují" do paměti. Pokud je
              třeba, vzorové věty průběžně zapisuji na tabuli. V této fázi jde tedy o vědomé soustředění na jednu nebo několik
              málo gramatických struktur, mezi nimiž se student postupně naučí podvědomě a sebejistě přepínat.
            </p>
            <p>
              U každého studenta má však dril svou hranici, kterou lze překročit jen komunikací a myšlením v cílovém jazyce. Tam
              nastupuje v mé koncepci <b>konverzační část</b>, kde komunikace probíhá kromě speciálních výrazů pouze v
              angličtině. Výukovými materiály jsou vybrané části anglických učebnic, příp. aktuální články, videa a prezentace z
              online zdrojů. V průběhu kurzu neustále recykluji starší slovní zásobu a srovnávám se slovní zásobou nově
              prezentovanou, vše na živých příkladových větách, přehledně na tabuli.
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <h2>3 P</h2>
            <p>
              Během svých lekcí se držím jednoho z nejuznávanějších principů výuky jazyků známého jako 3P nebo PPP. Funguje
              následovně:
            </p>
            <h3 style={{ marginBlockStart: 0, fontSize: "1.2rem" }}>Prezentace</h3>
            <p style={{ marginBlockStart: 0 }}>
              Nejprve vyložím nový aspekt jazyka ve známém kontextu, podobně, jako když trenér předvede sportovci novou techniku
              ​​cvičení. Zde je významný vklad učitele.
            </p>
            <h3 style={{ marginBlockStart: 0, fontSize: "1.2rem" }}>Praxe</h3>
            <p style={{ marginBlockStart: 0 }}>
              Ve druhé fázi je student vtažen do aktivit, v nichž s citlivou pomocí lektora procvičí nově zavedený jazyk.
              Chcete-li pokračovat v analogii, sportovec procvičuje novou techniku na tréninku, přičemž trenér poskytuje
              veškerou potřebnou pomoc, povzbuzení a pozitivní zpětnou vazbu. Zde se důraz přenáší na studenta.
            </p>
            <h3 style={{ marginBlockStart: 0, fontSize: "1.2rem" }}>Produkce</h3>
            <p style={{ marginBlockStart: 0 }}>
              Poslední fáze se zaměřuje na výkon, tj. samostatnou tvorbu, kdy student používá jazyk v kontextu a lektorova
              asistence je omezena na minimum. Podobně, jako když sportovec po náročném tréninku vstupuje do zápasu či závodu.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
