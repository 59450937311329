import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      backgroundColor: "#122E5C",
      width: 120,
      padding: "2px 8px",
      textAlign: "center",
      fontFamily: "Ludicrous, Roboto, sans-serif",
      color: "white",
      fontWeight: "bold",
      borderRadius: 4,
      "&:hover": {
        backgroundColor: "#394E6F",
      },
    },
    rootDisabled: {
      cursor: "not-allowed",
      backgroundColor: "#808080",
      "&:hover": {
        backgroundColor: "#808080",
      },
    },
    rootLoading: {
      cursor: "not-allowed",
      backgroundColor: "#32538a",
      "&:hover": {
        backgroundColor: "#32538a",
      },
    },
  })
);

interface IProps {
  element?: "div" | "span";
  label: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  id?: string;
}

export default function EFButton({ label, onClick, element, style, disabled, loading, loadingText, id }: IProps) {
  const classes = useStyles();

  element = element || "div";
  onClick = disabled === true || loading === true ? () => {} : onClick || (() => {});

  return element === "div" ? (
    <div
      id={id}
      style={style}
      className={`${classes.root} ${disabled ? classes.rootDisabled : ""} ${loading ? classes.rootLoading : ""}`}
      onClick={onClick}
    >
      {loading === true ? loadingText : label}
    </div>
  ) : (
    <span
      id={id}
      style={style}
      className={`${classes.root} ${disabled ? classes.rootDisabled : ""} ${loading ? classes.rootLoading : ""}`}
      onClick={onClick}
    >
      {loading === true ? loadingText : label}
    </span>
  );
}
