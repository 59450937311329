import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { api } from "../../Utils/ApiService";

export default function PrivacyPolicy() {
  const [conditions, setConditions] = useState("Podmínky se načítají");
  useEffect(() => {
    api.get(
      "/fileContent",
      { location: "config", file: "privacyPolicy.html" },
      {
        success: (c) => setConditions(c),
        error: () => alert("Při načítání podmínek"),
      }
    );
  }, []);

  return (
    <Container maxWidth="lg">
      <div dangerouslySetInnerHTML={{ __html: conditions }}></div>
    </Container>
  );
}
