import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "calc(100% - 24px)",
      backgroundSize: "cover",
      backgroundPositionX: "center",
      backgroundPositionY: "20%",
      height: 380,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      position: "absolute",
      top: 0,
      left: 0,
      padding: 12,
    },
    quote: {
      color: "white",
      textAlign: "justify",
      [theme.breakpoints.only("sm")]: {
        fontSize: "2.2rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.4rem",
      },
    },
    author: {
      fontFamily: "Dosis",
      color: "white",
      fontWeight: "bold",
      marginBlockStart: "0.2em",
      textAlign: "justify",
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.2rem",
      },
    },
  })
);

interface IProps {
  image: string;
  quotations: { text: string; author: string; duration: number }[];
}

export default function Quotation({ image, quotations }: IProps) {
  const classes = useStyles();

  const [fadeProp, setFadeProp] = useState({
    fade: "fade-in",
    notFade: "fade-out",
  });

  useEffect(() => {
    const timeout = setInterval(
      () => {
        if (fadeProp.fade === "fade-in") {
          setFadeProp({
            fade: "fade-out",
            notFade: "fade-in",
          });
        } else {
          setFadeProp({
            fade: "fade-in",
            notFade: "fade-out",
          });
        }
      },
      fadeProp.fade === "fade-in" ? quotations[0].duration : quotations[1]?.duration || 0
    );
    return () => clearInterval(timeout);
  }, [fadeProp, quotations]);

  return (
    <div style={{ position: "relative", width: "100%", height: 380 }}>
      <div className={classes.root} style={{ backgroundImage: `url(${image})` }}>
        <h2 className={`${fadeProp.fade} ${classes.quote}`}>{quotations[0].text}</h2>
        <h3 className={`${fadeProp.fade} ${classes.author}`}>{quotations[0].author}</h3>
      </div>
      <div className={classes.root} style={{ backgroundColor: `transparent` }}>
        <h2 className={`${fadeProp.notFade} ${classes.quote}`}>{quotations[1].text}</h2>
        <h3 className={`${fadeProp.notFade} ${classes.author}`}>{quotations[1].author}</h3>
      </div>
    </div>
  );
}
