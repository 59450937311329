import React from "react";
import { createStyles, Hidden, makeStyles, Theme } from "@material-ui/core";
import Flex from "./Flex";
import { CourseInfo } from "../Types/courses";
import useWindowDimensions from "../Hooks/GetWindowDimensions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    level: {
      borderRadius: 4,
      cursor: "pointer",
      textAlign: "center",
      color: "#122e5c",
      marginBottom: 38,
      fontFamily: "Dosis, Roboto, sans-serif",
      fontWeight: "bold",
      position: "relative",
      "&:hover": {
        opacity: 0.8,
      },
      [theme.breakpoints.only("xs")]: {
        marginBottom: 8,
      },
    },
    levelText: {
      position: "absolute",
      color: "#4d4747",
      font: "Dosis, Roboto, sans-serif",
      width: "100%",
      textAlign: "center",
      top: 4,
      left: 0,
      fontSize: "0.7rem",
    },
    snake: {
      height: 620,
      marginTop: 26,
      marginRight: 8,
      marginLeft: 8,
    },
  })
);

interface IProps {
  allCourses: string[];
  courses: {
    [index: string]: CourseInfo;
  };
  setSelectedCourse: (val: string) => void;
  selectedCourse: string;
}

export default function LevelsSnake({ selectedCourse, allCourses, courses, setSelectedCourse }: IProps) {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <>
      <Hidden xsDown>
        <Flex center>
          <div style={{ textAlign: "center" }}>
            {Array.from(new Array(allCourses.length / 2)).map((_, i) => (
              <div
                onClick={() => {
                  if (width < 960) {
                    // @ts-ignore
                    window.scrollTo({
                      top: document.getElementById("courseDescriptionBox")?.offsetTop,
                      left: 0,
                      behavior: "smooth",
                    });
                  }
                  setSelectedCourse(allCourses[2 * i]);
                }}
                className={classes.level}
                key={i}
                style={{
                  backgroundColor: courses[allCourses[2 * i]].color,
                  border: selectedCourse === allCourses[2 * i] ? "2px solid #122e5c" : "1px solid #d1cbcb",
                  padding: selectedCourse === allCourses[2 * i] ? 31 : 32,
                  paddingTop: selectedCourse === allCourses[2 * i] ? 3 : 4,
                  paddingBottom: selectedCourse === allCourses[2 * i] ? 11 : 12,
                }}
              >
                <br />
                {courses[allCourses[2 * i]].title}
                <br />
                {courses[allCourses[2 * i]].type}
                <div className={classes.levelText}>{courses[allCourses[2 * i]].level}</div>
              </div>
            ))}
          </div>
          <img src="/img/snake.svg" alt="" className={classes.snake} />
          <div style={{ paddingTop: 25 }}>
            {Array.from(new Array(allCourses.length / 2)).map((_, i) => (
              <div
                onClick={() => {
                  if (width < 960) {
                    // @ts-ignore
                    window.scrollTo({
                      top: document.getElementById("courseDescriptionBox")?.offsetTop,
                      left: 0,
                      behavior: "smooth",
                    });
                  }
                  setSelectedCourse(allCourses[2 * i + 1]);
                }}
                className={classes.level}
                key={i}
                style={{
                  backgroundColor: courses[allCourses[2 * i + 1]].color,
                  border: selectedCourse === allCourses[2 * i + 1] ? "2px solid #122e5c" : "1px solid #d1cbcb",
                  padding: selectedCourse === allCourses[2 * i] ? 31 : 32,
                  paddingTop: selectedCourse === allCourses[2 * i] ? 3 : 4,
                  paddingBottom: selectedCourse === allCourses[2 * i] ? 11 : 12,
                }}
              >
                <br />
                {courses[allCourses[2 * i + 1]].title}
                <br />
                {courses[allCourses[2 * i + 1]].type}
                <div className={classes.levelText}>{courses[allCourses[2 * i + 1]].level}</div>
              </div>
            ))}
          </div>
        </Flex>
      </Hidden>
      <Hidden smUp>
        {Array.from(new Array(allCourses.length)).map((_, i) => (
          <div
            onClick={() => {
              if (width < 960) {
                // @ts-ignore
                window.scrollTo({
                  top: document.getElementById("courseDescriptionBox")?.offsetTop,
                  left: 0,
                  behavior: "smooth",
                });
              }
              setSelectedCourse(allCourses[i]);
            }}
            className={classes.level}
            key={i}
            style={{
              backgroundColor: courses[allCourses[i]].color,
              border: selectedCourse === allCourses[i] ? "2px solid #122e5c" : "1px solid #d1cbcb",
              padding: selectedCourse === allCourses[i] ? 31 : 32,
              paddingTop: selectedCourse === allCourses[i] ? 3 : 4,
              paddingBottom: selectedCourse === allCourses[i] ? 11 : 12,
            }}
          >
            <br />
            {courses[allCourses[i]].title}
            <br />
            {courses[allCourses[i]].type}
            <div className={classes.levelText}>{courses[allCourses[i]].level}</div>
          </div>
        ))}
      </Hidden>
    </>
  );
}
