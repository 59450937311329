import React from "react";
import { TextField, withStyles } from "@material-ui/core";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "white",
    padding: "0",
    "& label": {
      color: "#122e5c",
      fontFamily: "Dosis, Roboto, sans-serif",
      fontWeight: "bold",
      fontSize: "1.2rem",
    },
    "& label.Mui-focused": {
      fontSize: "0.8rem",
    },
    "& label.MuiInputLabel-shrink": {
      fontSize: "0.8rem",
    },
    "& .MuiInputBase-root": {
      backgroundColor: "white",
      borderBottom: "none",
    },
    "& input": {
      fontFamily: "Dosis, Roboto, sans-serif",
      color: "#122e5c",
      fontWeight: "bold",
    },
    "& textarea": {
      fontFamily: "Dosis, Roboto, sans-serif",
      color: "#122e5c",
      fontWeight: "bold",
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none",
    },
  },
})(TextField);

interface IProps {
  label: string;
  fullWidth?: boolean;
  multiline?: boolean;
  rows?: number;
  value?: string;
  onChange?: (newVal: string) => void;
}

export default function EFTextField({ label, fullWidth, multiline, rows, onChange, value }: IProps) {
  return (
    <CustomTextField
      label={label}
      fullWidth={fullWidth}
      variant="filled"
      multiline={multiline}
      rows={rows}
      value={value}
      onChange={onChange === undefined ? undefined : (e) => onChange(e.target.value)}
    />
  );
}
