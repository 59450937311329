import React from "react";
import { SwipeableDrawer, List, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const useStyles = makeStyles({
  list: {
    width: 240,
  },
  selected: {
    color: "white",
    backgroundColor: "#122E5C",
  },
});

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function RightDrawer({ open, setOpen }: IProps) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  return (
    <SwipeableDrawer anchor="right" open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
      <List className={classes.list} style={{ flexGrow: 1 }}>
        <ListItem
          button
          onClick={() => {
            history.push("/");
            setOpen(false);
          }}
          className={location.pathname === "/" ? classes.selected : ""}
        >
          <ListItemText primary="ÚVOD" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/teaching");
            setOpen(false);
          }}
          className={location.pathname.includes("teaching") ? classes.selected : ""}
        >
          <ListItemText primary="VÝUKA" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/publicTeaching");
            setOpen(false);
          }}
          className={location.pathname.includes("publicTeaching") ? classes.selected : ""}
        >
          <ListItemText primary="SKUPINOVÉ KURZY PRO VEŘEJNOST" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/companyPrivateTeaching");
            setOpen(false);
          }}
          className={location.pathname.includes("companyPrivateTeaching") ? classes.selected : ""}
        >
          <ListItemText primary="INDIVIDUÁLNÍ A FIREMNÍ KURZY" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/contact");
            setOpen(false);
          }}
          className={location.pathname.includes("contact") ? classes.selected : ""}
        >
          <ListItemText primary="KONTAKT" />
        </ListItem>
        <ListItem
          button
          onClick={() => {
            history.push("/studentZone");
            setOpen(false);
          }}
          className={location.pathname.includes("studentZone") ? classes.selected : ""}
        >
          <ListItemText primary="STUDENTSKÁ ZÓNA" />
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
}
