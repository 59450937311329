import React, { useEffect } from "react";
import Methodics from "./Methodics";
import Levels from "./Levels";
import Forms from "./Forms";
import Router from "./Router";
import Quotation from "../../Components/Quotation";
import { useLocation } from "react-router";

export default function Teaching() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash.length > 1) {
      window.scrollTo({ top: document.getElementById(location.hash.replace("#", ""))?.offsetTop, left: 0 });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Methodics />
      <Quotation
        quotations={[
          {
            text: "Theory is knowledge that doesn't work. Practice is when everything works and you don't know why.",
            author: "Herman Hesse",
            duration: 10000,
          },
          {
            text: "Teorie je znalost, která nefunguje. Praxe je, když všechno funguje a vy nevíte proč.",
            author: "Herman Hesse",
            duration: 5000,
          },
        ]}
        image="/img/quotation.jpg"
      />
      <Levels />
      <Forms />
      <Router />
    </>
  );
}
