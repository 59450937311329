import React from "react";
import { Container, createStyles, Grid, Hidden, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Theme } from "@material-ui/core";
import { Price } from "../../Types/courses";
import { separateThousands } from "../../Utils/Common";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 32,
      paddingBottom: 64,
      backgroundColor: "#122e5c",
    },
    white: {
      color: "white",
    },
    table: {
      color: "white !important",
      fontFamily: "Dosis, Roboto, sans-serif",
      [theme.breakpoints.down("xs")]: {
        padding: 4,
      },
    },
  })
);

interface IProps {
  prices?: { [index: string]: Price };
}

export default function Pricing({ prices }: IProps) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <h3 id="ceny" className={classes.white}>
          POČET STUDENTŮ A CENY V JEDNOTLIVÝCH TYPECH KURZŮ
        </h3>
        <p className={classes.white}>
          <b>Gramatické kurzy</b> jsou navrženy a optimalizovány pro 6 až 18 studentů, přičemž zvýhodněné ceny First Minute 2T+/4T+ při včasném objednání a zaplacení jsou nastaveny bezkonkurenčně nízko.
        </p>
        {(prices && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.table}>
                  <Hidden xsDown>gramatické kurzy</Hidden>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  standardní cena<sup style={{ color: "red" }}>*</sup>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <Hidden xsDown>zvýhodněná cena </Hidden>First Minute 2T+<sup style={{ color: "red" }}>**</sup>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <Hidden xsDown>zvýhodněná cena </Hidden>First Minute 4T+<sup style={{ color: "red" }}>**</sup>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.table}>
                  <b>řádné</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["GRNorm"].normal)} Kč</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["GRNorm"].twoWeeks)} Kč</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["GRNorm"].fourWeeks)} Kč</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.table}>
                  <b>kondiční</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["GRCond"].normal)} Kč</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["GRCond"].twoWeeks)} Kč</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["GRCond"].fourWeeks)} Kč</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )) || <p>Načítám ceny...</p>}
        <br />
        <p className={classes.white}>
          <b>Konverzační kurzy</b> jsou navrženy a optimalizovány pro 3 až 6 studentů, přičemž zvýhodněné ceny First Minute 2T+/4T+ při včasném objednání a zaplacení jsou více než vstřícné.
        </p>
        {(prices && (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.table}>
                  <Hidden xsDown>konverzační kurzy</Hidden>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  standardní cena<sup style={{ color: "red" }}>*</sup>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <Hidden xsDown>zvýhodněná cena</Hidden> First Minute 2T+<sup style={{ color: "red" }}>**</sup>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <Hidden xsDown>zvýhodněná cena</Hidden> First Minute 4T+<sup style={{ color: "red" }}>**</sup>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.table}>
                  <b>řádné</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["CONorm"].normal)} Kč</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["CONorm"].twoWeeks)} Kč</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["CONorm"].fourWeeks)} Kč</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.table}>
                  <b>kondiční</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["COCond"].normal)} Kč</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["COCond"].twoWeeks)} Kč</b>
                </TableCell>
                <TableCell className={classes.table} style={{ textAlign: "center" }}>
                  <b>{separateThousands(prices["COCond"].fourWeeks)} Kč</b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )) || <p>Načítám ceny...</p>}
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <p style={{ color: "white", fontSize: "0.8rem" }}>
              <sup style={{ color: "red" }}>*</sup>
              Standardní cena kurzu znamená, že student má při včasném odstoupení z kurzu nárok na vrácení kurzovného sníženého o stornopoplatek podle následujících podmínek:
              <br />
              a) v případě odstoupení od smlouvy 14 a více dnů před začátkem kurzu činí stornopoplatek 10% ceny kurzu jako kompenzace administrativních nákladů dodavatele
              <br />
              b) v případě odstoupení od smlouvy 10 až 13 dnů před začátkem kurzu činí stornopoplatek 30% ceny kurzu
              <br />
              c) v případě odstoupení od smlouvy 6 až 9 dnů před začátkem kurzu činí stornopoplatek 70% ceny kurzu
              <br />
              d) v případě odstoupení od smlouvy 5 a méně dnů před začátkem kurzu činí stornopoplatek 100% ceny kurzu
              <br />
            </p>
          </Grid>{" "}
          <Grid item xs={12} md={6}>
            <p style={{ color: "white", fontSize: "0.8rem" }}>
              <sup style={{ color: "red" }}>**</sup>
              Zvýhodněná cena First Minute 2T+ je cena platná při objednání a uhrazení platby méně než 28 dnů a alespoň 14 dnů před vypsaným začátkem kurzu.
            </p>
            <p style={{ color: "white", fontSize: "0.8rem" }}>
              <sup style={{ color: "red" }}>**</sup>
              Zvýhodněná cena First Minute 4T+ je cena platná při objednání a uhrazení platby alespoň 28 dnů před vypsaným začátkem kurzu. V obou výše zmíněných případech platí, že objednatel se touto volbou zříká nároku na pozdější vrácení kurzovného, ale
              může po dohodě s dodavatelem účast v kurzu postoupit jinému klientovi ze svého okolí.
            </p>
            <p style={{ color: "white", fontSize: "0.8rem" }}>
              viz{" "}
              <u style={{ cursor: "pointer" }} onClick={() => history.push("/conditions")}>
                Obchodní podmínky
              </u>
              , čl. VI. bod 3.
            </p>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
