import React, { useEffect } from "react";
import VisitCard from "./VisitCard";
import ContactForm from "./ContactForm";
import { useLocation } from "react-router";

export default function Contact() {
  const location = useLocation();

  useEffect(
    () => {
      if (location.hash === "#contactForm") {
        window.scrollTo({ top: document.getElementById("contactForm")?.offsetTop, left: 0 });
      }
    },
    //eslint-disable-next-line
    []
  );

  return (
    <>
      <VisitCard />
      <ContactForm />
    </>
  );
}
