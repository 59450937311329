import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    londonImage: {
      width: "100vw",
      backgroundImage: "url(/img/newYork.jpg)",
      height: "max(256px, 60vh)",
      backgroundSize: "cover",
      backgroundPositionY: "center",
      position: "relative",
      paddingTop: "4vh",
    },
    welcomeText: {
      fontSize: "4.2rem",
      color: "white",
      textAlign: "center",
      lineHeight: 1.4,
      fontFamily: "Dosis, Roboto, sans-serif",
      fontWeight: "bolder",
      textShadow: "2px 2px black, -2px -2px black",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.8rem",
      },
    },
    englishText: {
      fontSize: "4.2rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.8rem",
      },
    },
    welcomeTextSubtext: {
      color: "white",
      fontSize: "2.8rem",
      textAlign: "center",
      fontFamily: "Dosis, Roboto, sans-serif",
      fontWeight: 1000,
      textShadow: "1.2px 1.2px black, -1.2px -1.2px black",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.4rem",
      },
    },
    quotation: {
      width: "100%",
      position: "absolute",
      bottom: 0,
      paddingTop: 16,
      paddingBottom: 16,
      fontSize: "2rem",
      textAlign: "center",
      fontFamily: "LudicrousStencil, Roboto, sans-serif",
      // textShadow: "-6.5px 6.5px black",
      color: "white",
      fontWeight: 1000,
      backgroundColor: "rgba(0,0,0,0.32)",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
    quotationAuthor: {
      fontWeight: 500,
      fontSize: "1.6rem",
      fontFamily: "Dosis, Roboto, sans-serif",
      [theme.breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.6rem",
      },
    },
  })
);

export default function HomeBanner() {
  const classes = useStyles();
  const [fadeProp, setFadeProp] = useState({
    fade: "fade-in",
    notFade: "fade-out",
  });

  useEffect(() => {
    const timeout = setInterval(
      () => {
        if (fadeProp.fade === "fade-in") {
          setFadeProp({
            fade: "fade-out",
            notFade: "fade-in",
          });
        } else {
          setFadeProp({
            fade: "fade-in",
            notFade: "fade-out",
          });
        }
      },
      fadeProp.fade === "fade-in" ? 10000 : 5000
    );
    return () => clearInterval(timeout);
  }, [fadeProp]);

  return (
    <div className={classes.londonImage}>
      <div className={classes.welcomeText}>
        Marek Chocholatý
        <br />
        <span className={classes.englishText}>výuka angličtiny online</span>
      </div>
      <br />
      <div className={classes.welcomeTextSubtext}>drilovou a komunikativní metodou</div>
      <div className={`${classes.quotation} ${fadeProp.fade}`}>
        A foreign language is like a frail, delicate muscle. If you do not use it, it weakens.{" "}
        <span className={classes.quotationAuthor}>–&nbsp;Jhumpa&nbsp;Lahiri</span>
      </div>
      <div className={`${classes.quotation} ${fadeProp.notFade}`}>
        Cizí jazyk je jako křehký, citlivý sval. Když jej nepoužíváte, zeslábne.{" "}
        <span className={classes.quotationAuthor}>–&nbsp;Jhumpa&nbsp;Lahiri</span>
      </div>
    </div>
  );
}
