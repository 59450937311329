import React, { useEffect } from "react";
import { Container, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "calc(100vh - 400px)",
      textAlign: "center",
      paddingTop: 64,
    },
  })
);

export default function StudentZone() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <h2>Připravujeme pro Vás studentskou zónu</h2>
        <p style={{ textAlign: "center" }}>Tato část stránek je stále ve vývoji.</p>
      </Container>
    </div>
  );
}
