import React, { useEffect } from "react";
import HomeBanner from "./HomeBanner";
import AboutMe from "./AboutMe";
import Recension from "./Recension";
import WhyToLearnWithMe from "./WhyToLearnWithMe";
import { useLocation } from "react-router";

export default function Home() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash.length > 1) {
      window.scrollTo({ top: document.getElementById(location.hash.replace("#", ""))?.offsetTop, left: 0 });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <HomeBanner />
      <AboutMe />
      <br />
      <br />
      <br />
      <WhyToLearnWithMe />
      <br />
      <Recension />
    </>
  );
}
