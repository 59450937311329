import React, { useEffect, useState } from "react";
import Form from "./Form";
import Pricing from "./Pricing";
import { Courses as TCourses } from "../../Types/courses";
import { api } from "../../Utils/ApiService";
import SemesterHoliday from "./SemesterHoliday";
import Levels from "./Levels";
import Quotation from "../../Components/Quotation";
import { useLocation } from "react-router";

export default function PublicTeaching() {
  const [info, setInfo] = useState<TCourses | undefined>(undefined);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const location = useLocation();

  useEffect(() => {
    api.get(
      "/fileContent",
      { location: "config", file: "courses.json" },
      {
        success: (c) => {
          setInfo(JSON.parse(c));
        },
        error: () => alert("Při načítání kurzů došlo k chybě"),
      }
    );
  }, []);

  useEffect(() => {
    if (location.hash.length > 1) {
      window.scrollTo({ top: document.getElementById(location.hash.replace("#", ""))?.offsetTop, left: 0 });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SemesterHoliday info={info?.courseIntro} />
      <Quotation
        quotations={[
          {
            text: "Learning another language is like becoming another person.",
            author: "Haruki Murakami",
            duration: 10000,
          },
          {
            text: "Učit se jiný jazyk je jako stát se jiným člověkem.",
            author: "Haruki Murakami",
            duration: 5000,
          },
        ]}
        image="/img/quotation2.jpg"
      />
      <Levels
        info={info}
        drawCourses
        signUp={(level: string, course: string) => {
          setSelectedCourse(course);
          setSelectedLevel(level);
          // @ts-ignore
          window.scrollTo({ top: document.getElementById("publicCoursesSignUpForm")?.offsetTop, left: 0, behavior: "smooth" });
        }}
      />
      <Pricing prices={info?.prices} />
      <Form
        info={info}
        setSelectedCourse={setSelectedCourse}
        setSelectedLevel={setSelectedLevel}
        selectedCourse={selectedCourse}
        selectedLevel={selectedLevel}
      />
    </>
  );
}
