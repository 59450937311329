import React, { useEffect } from "react";
import Organization from "./Organization";
import Content from "./Content";
import Form from "./Form";
import Quotation from "../../Components/Quotation";
import { useLocation } from "react-router";

export default function CompanyPrivateTeaching() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash.length > 1) {
      window.scrollTo({ top: document.getElementById(location.hash.replace("#", ""))?.offsetTop, left: 0 });
    } else {
      window.scrollTo({ top: 0, left: 0 });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Content />
      <Quotation
        quotations={[
          {
            text: "Tell me and I forget. Teach me and I remember. Involve me and I learn.",
            author: "Benjamin Franklin",
            duration: 10000,
          },
          {
            text: "Řekni mi a já zapomenu.  Uč mě a já si zapamatuju.  Zapoj mě a já se naučím.",
            author: "Benjamin Franklin",
            duration: 5000,
          },
        ]}
        image="/img/quotation3.jpg"
      />
      <Organization />
      <Form />
    </>
  );
}
