import React from "react";
import {
  createStyles,
  FormControl,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiInputLabel-root": {
        zIndex: 999,
        left: 12,
        top: 4,
        color: "#122e5c",
        fontFamily: "Dosis, Roboto, sans-serif",
        fontWeight: "bold",
        fontSize: "1.2rem",
      },
      "& .MuiInputLabel-shrink": {
        fontSize: "0.8rem",
      },
    },
  })
);

const CustomInput = withStyles({
  root: {
    backgroundColor: "white",
    paddingBottom: 6,
    paddingTop: 18,
    paddingLeft: 12,
    color: "#122e5c",
    fontFamily: "Dosis, Roboto, sans-serif",
    fontWeight: "bold",
    fontSize: "1.2rem",
    zIndex: 0,
  },
})(InputBase);

interface IProps {
  label: string;
  value: string;
  options: { key: string; value: string }[];
  onChange: (newVal: string) => void;
}

export default function EFSelect({ onChange, options, value, label }: IProps) {
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.root}>
      <InputLabel id="demo-customized-select-label">{label}</InputLabel>
      <Select
        labelId="demo-customized-select-label"
        value={value}
        onChange={(e) => onChange(e.target.value as string)}
        input={<CustomInput />}
      >
        {options.map((o) => (
          <MenuItem key={o.key} value={o.key}>
            {o.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
