import React, { useState } from "react";
import { Container, createStyles, Grid, Hidden, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "#FBF6E5",
      paddingTop: 64,
      paddingBottom: 32,
    },
    reasonCard: {
      borderRadius: 8,
      width: "90%",
      marginLeft: "5%",
      backgroundColor: "#FDF1C8",
      position: "relative",
    },
    reasonContainer: {
      cursor: "pointer",
      "&:hover": {
        opacity: "80%",
      },
    },
    reasonTitle: {
      color: "#122E5C",
      fontFamily: "Ludicrous, Roboto, sans-serif",
      fontSize: "1.8rem",
      textAlign: "center",
    },
    reasonSubtitle: {
      color: "#122E5C",
      fontFamily: "Dosis, Roboto, sans-serif",
      fontSize: "0.8rem",
      textAlign: "center",
    },
    reasonText: {
      padding: 32,
    },
    reasonTextTitle: {
      color: "#155dc4",
      fontFamily: "Roboto, sans-serif",
      fontSize: "1.2rem",
    },
    reasonCardTail: {
      backgroundColor: "#122E5C",
      width: 2,
      left: "50%",
      bottom: -18,
      height: 18,
      position: "absolute",
    },
    semHolCard: {
      [theme.breakpoints.up("md")]: {
        borderTop: "2px solid #122E5C",
        borderRadius: 12,
      },
    },
  })
);

interface IProps {
  info?: {
    [index: string]: {
      description: string;
      courses: {
        label: string;
        dates: string;
        dotation: number;
      }[];
    };
  };
}

export default function SemesterHoliday({ info }: IProps) {
  const classes = useStyles();

  const [selectedType, setSelectedType] = useState<"HOL" | "SEM" | "NONE">("SEM");

  return (
    <div className={classes.root} id="top">
      <Container maxWidth="lg">
        <h2 id="kurzydleintenzity">ČLENĚNÍ KURZŮ PODLE ČASOVÉHO PLÁNU A INTENZITY </h2>
        <p>
          Všechny moje gramatické a konverzační kurzy pro veřejnost momentálně probíhají pouze distančně / živě online přes
          videokonfereční platformu Zoom. Každý nový student má možnost zdarma absolvovat vstupní test, příp. osobní pohovor,
          který jej v případě nerozhodnosti nasměruje do správné pokročilosti.
        </p>
        <p>
          Přes školní rok, od října do května, nabízím zejména řádné dvousemestrální / 8-měsíční kurzy běžící 1 x 2 vyuč. hod.
          týdně a semestrální / 4-měsíční kurzy běžící 2 x 2 vyuč. hod. týdně, případně víkendové měsíční kurzy probíhající ve
          třech výukových víkendech, mezi nimiž je vždy jeden víkend volna.
        </p>
        <p>
          V době hlavních prázdnin, červen až září, jsou to zejména řádné letní kurzy běžící jako intenzivní 2-týdenní kurzy,
          kde je šest výukových dnů rovnoměrně rozloženo do dvou týdnů, případně večerní 2-měsíční kurzy, vždy 2 x 4 vyuč. hod.
          týdně.
        </p>
        <p>
          Všechny tyto <b>ŘÁDNÉ KURZY</b> prezentují a procvičují novou gramatiku a slovní zásobu postupným způsobem s plnou
          hodinovou dotací a garantují nácvik všech dovedností nezbytných pro postup do vyšší úrovně.
        </p>
        <p>
          Jistou specialitou mé nabídky jsou <b>KONDIČNÍ KURZY</b>, které spíše shrnují a "oprašují" dříve získané dovednosti,
          procházejí hlavní okruhy gramatiky a slovní zásoby dané pokročilosti zrychleně a stručněji se sníženou hodinovou
          dotací. Jde o to, aby student získal v krátké době jistotu v požadovaných dovednostech ještě předtím, než postoupí do
          vyšší úrovně.
        </p>
        <p>
          Jsou spouštěny průběžně během celého roku, buď jako kondiční víkendové, tj. celá sobota plus neděle dopoledne, anebo
          jako kondiční týdenní, tj. pondělí až čtvrtek odpoledne nebo večer.
        </p>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} onClick={() => setSelectedType("SEM")} className={classes.reasonContainer}>
            <div className={classes.reasonCard} style={selectedType === "SEM" ? { backgroundColor: "#122E5C" } : {}}>
              <div style={{ padding: 16, paddingRight: 0 }}>
                <div className={classes.reasonTitle} style={selectedType === "SEM" ? { color: "white" } : {}}>
                  Semestrální kurzy
                </div>
                <div className={classes.reasonSubtitle} style={selectedType === "SEM" ? { color: "white" } : {}}>
                  říjen - květen
                </div>
              </div>
              <Hidden smDown>{selectedType === "SEM" && <div className={classes.reasonCardTail}></div>}</Hidden>
            </div>
          </Grid>
          <Grid item xs={12} md={6} onClick={() => setSelectedType("HOL")} className={classes.reasonContainer}>
            <div className={classes.reasonCard} style={selectedType === "HOL" ? { backgroundColor: "#122E5C" } : {}}>
              <div style={{ padding: 16, paddingRight: 0 }}>
                <div className={classes.reasonTitle} style={selectedType === "HOL" ? { color: "white" } : {}}>
                  Letní kurzy
                </div>
                <div className={classes.reasonSubtitle} style={selectedType === "HOL" ? { color: "white" } : {}}>
                  červen - září
                </div>
              </div>
              <Hidden smDown>{selectedType === "HOL" && <div className={classes.reasonCardTail}></div>}</Hidden>
            </div>
          </Grid>
        </Grid>
        <br />
        {selectedType !== "NONE" && (
          <>
            {(info && (
              <div className={classes.semHolCard}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <h3>Řádné kurzy</h3>
                    <p>{info[selectedType + "Norm"].description}</p>
                    {info[selectedType + "Norm"].courses.map((c, i) => (
                      <React.Fragment key={i}>
                        <span style={{ fontFamily: "Dosis", color: "#122e5c", fontSize: "1.2rem" }}>
                          <u>{c.label}</u>
                        </span>
                        <br />
                        <span style={{ fontFamily: "Dosis", color: "#122e5c", fontSize: "1.2rem" }}>{c.dates}</span>
                        <br />
                        <span style={{ fontFamily: "Dosis", color: "#122e5c", fontSize: "1.2rem" }}>
                          celkem {c.dotation} vh
                        </span>
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h3>Kondiční kurzy</h3>
                    <p>{info[selectedType + "Cond"].description}</p>
                    {info[selectedType + "Cond"].courses.map((c) => (
                      <>
                        <span style={{ fontFamily: "Dosis", color: "#122e5c", fontSize: "1.2rem" }}>
                          <u>{c.label}</u>
                        </span>
                        <br />
                        <span style={{ fontFamily: "Dosis", color: "#122e5c", fontSize: "1.2rem" }}>{c.dates}</span>
                        <br />
                        <span style={{ fontFamily: "Dosis", color: "#122e5c", fontSize: "1.2rem" }}>
                          celkem {c.dotation} vh
                        </span>
                        <br />
                        <br />
                      </>
                    ))}
                  </Grid>
                </Grid>
              </div>
            )) || <p>Informace o kurzech se načítají</p>}
          </>
        )}
      </Container>
    </div>
  );
}
