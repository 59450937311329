import React from "react";
import { Checkbox, createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    label: {
      fontFamily: "Dosis, Roboto, sans-serif",
      color: "#122e5c",
      fontSize: "1.2rem",
    },
    subLabel: {
      fontFamily: "Dosis, Roboto, sans-serif",
      color: "#122e5c",
      fontSize: "0.8rem",
    },
    chroot: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      width: 28,
      height: 28,
      backgroundColor: "#ffffff",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ffffff",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#122e5c",
      backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 28,
        height: 28,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#122e5c",
      },
    },
  })
);

interface IProps {
  checked: boolean;
  setChecked?: (val: boolean) => void;
  onClick?: () => void;
  label: string;
  subLabel: string;
}

export default function EFCheckbox({ checked, setChecked, onClick, label, subLabel }: IProps) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      onClick={setChecked === undefined ? () => (onClick || (() => {}))() : () => setChecked(!checked)}
    >
      <Checkbox
        className={classes.chroot}
        checked={checked}
        disableRipple
        color="default"
        checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
        icon={<span className={classes.icon} />}
        inputProps={{ "aria-label": "decorative checkbox" }}
      />
      &nbsp;&nbsp;&nbsp;
      <div>
        <div className={classes.label}>{label}</div>
        <div className={classes.subLabel}>{subLabel}</div>
      </div>
    </div>
  );
}
