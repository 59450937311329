import React, { useState } from "react";
import {
  Checkbox,
  Container,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Snackbar,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import EFTextField from "../../Components/EFTextField";
import EFButton from "../../Components/EFButton";
import EFSelect from "../../Components/EFSelect";
import { Course, Courses } from "../../Types/courses";
import { separateThousands } from "../../Utils/Common";
import { api } from "../../Utils/ApiService";
import EFCheckbox from "../../Components/EFCheckbox";
import { Alert } from "@material-ui/lab";
import useWindowDimensions from "../../Hooks/GetWindowDimensions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "#FBF6E5",
      paddingTop: 4,
      paddingBottom: 96,
    },
    tableCell: {
      fontFamily: "Dosis, Roboto, sans-serif",
      color: "#122e5c",
      fontSize: "1.1rem",
    },
  })
);
interface IProps {
  info?: Courses;
  selectedLevel: string;
  selectedCourse: string;
  setSelectedLevel: (val: string) => void;
  setSelectedCourse: (val: string) => void;
}

const emptyFormState = {
  personType: "person",
  name: "",
  surname: "",
  bornDate: "01.01.2000",
  street: "",
  houseNum: "",
  city: "",
  zip: "",
  mail: "",
  phone: "",
  info: "",

  companyNumber: "",
  taxId: "",
  companyName: "",

  differentBillingAddress: false,
  billingName: "",
  billingSurname: "",
  billingStreet: "",
  billingHouseNum: "",
  billingCity: "",
  billingZip: "",
  billingCompanyNumber: "",
  billingTaxId: "",
  billingCompanyName: "",
};

export default function Form({ info, selectedCourse, selectedLevel, setSelectedCourse, setSelectedLevel }: IProps) {
  const classes = useStyles();

  const [formState, setFormState] = useState(emptyFormState);
  const [agreeWithConditions, setAgreeWithConditions] = useState(false);
  const [useBasePrice, setUseBasePrice] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const { width } = useWindowDimensions();

  const selectedCourseInfo = (info?.courses[selectedLevel] || { courses: [] }).courses.filter(
    (c) => c.code === selectedCourse
  )[0] as Course;

  const isFormValid =
    selectedCourse.length > 0 &&
    formState.name.length > 0 &&
    formState.surname.length > 0 &&
    formState.bornDate.length > 0 &&
    formState.street.length > 0 &&
    formState.houseNum.length > 0 &&
    formState.city.length > 0 &&
    formState.zip.length > 0 &&
    formState.mail.length > 0 &&
    formState.phone.length > 0 &&
    agreeWithConditions;

  const submit = () => {
    setLoading(true);
    const d = new Date(selectedCourseInfo.start);
    d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
    if (isFormValid) {
      api.post(
        "/publicSignUp",
        {
          courseStartDate: d.toISOString(),
          courseTitle: `${info?.courses[selectedLevel].title} - ${info?.courses[selectedLevel].type}, ${selectedCourseInfo.name}`,
          courseDate: `${selectedCourseInfo.dates}`,
          courseTimes: selectedCourseInfo.times,
          deadlineLimit: useBasePrice
            ? (() => {
                let res = new Date(selectedCourseInfo.start);
                res.setDate(res.getDate() - 7);
                return res;
              })().toISOString()
            : getPaymentDeadline(selectedCourseInfo).toISOString(),
        },
        {
          course: selectedCourse,
          name: formState.name,
          surname: formState.surname,
          street: formState.street,
          houseNumber: formState.houseNum,
          zip: formState.zip,
          city: formState.city,
          mail: formState.mail,
          phone: formState.phone,
          additionalInfo: formState.info,
          id: null,
          paid: false,
          createdDate: null,
          personType: formState.personType,
          companyNumber: formState.companyNumber,
          companyName: formState.companyName,
          taxId: formState.taxId,
          differentBillingAddress: formState.differentBillingAddress,
          billingName: formState.billingName,
          billingSurname: formState.billingSurname,
          billingStreet: formState.billingStreet,
          billingHouseNumber: formState.billingHouseNum,
          billingZip: formState.billingZip,
          billingCity: formState.billingCity,
          billingCompanyNumber: formState.billingCompanyNumber,
          billingTaxId: formState.billingTaxId,
          billingCompanyName: formState.billingCompanyName,
          useBasePrice: useBasePrice,
          price: useBasePrice
            ? getCourseBasePrice(selectedCourseInfo, selectedLevel.substring(2, 4))
            : getCoursePrice(selectedCourseInfo, selectedLevel.substring(2, 4)),
          variableSymbol: null,
        },
        {
          success: () => {
            setFormState(emptyFormState);
            setAgreeWithConditions(false);
            setSelectedCourse("");
            setSelectedLevel("");
            setUseBasePrice(false);
            setOpenAlert(true);
            setLoading(false);
          },
          error: () => {
            alert("Při odesílání přihlášky došlo k chybě. Zkuste to prosím později a případně nás prosím kontaktujte.");
            setLoading(false);
          },
        }
      );
    }
  };

  const getPaymentDeadline = (course: Course) => {
    const twoWeeksMillis = 1209600000;
    const fourWeeksMillis = 2419200000;
    const start = new Date(course.start);
    const today = new Date();
    const diffTime = start.getTime() - today.getTime();
    if (diffTime > fourWeeksMillis) {
      start.setDate(start.getDate() - 28);
      return start;
    }
    if (diffTime > twoWeeksMillis) {
      start.setDate(start.getDate() - 14);
      return start;
    }
    start.setDate(start.getDate() - 7);
    return start;
  };

  const getCoursePrice = (course: Course, type: string) => {
    const twoWeeksMillis = 1209600000;
    const fourWeeksMillis = 2419200000;
    const start = new Date(course.start);
    const today = new Date();
    const diffTime = start.getTime() - today.getTime();
    if (diffTime < 0) return -1;
    const priceCategory = info?.prices[type.toUpperCase() + course.priceCategory];
    if (diffTime > fourWeeksMillis) {
      return priceCategory?.fourWeeks;
    }
    if (diffTime > twoWeeksMillis) {
      return priceCategory?.twoWeeks;
    }
    return priceCategory?.normal;
  };

  const getCourseBasePrice = (course: Course, type: string) => {
    const priceCategory = info?.prices[type.toUpperCase() + course.priceCategory];
    return priceCategory?.normal;
  };

  return (
    <div className={classes.root} id="publicCoursesSignUpForm">
      <Container maxWidth="lg">
        <h2 id="prihlaska">PŘIHLÁŠKA DO KURZU</h2>
        <h3 style={{ fontFamily: "Dosis, Roboto, sans-serif", fontWeight: "bold" }}>Vybrat kurz:</h3>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <EFSelect
              options={
                info?.allCourses.map((cid) => {
                  return { key: cid, value: `${info.courses[cid].title} ${info.courses[cid].type}` };
                }) || []
              }
              value={selectedLevel}
              onChange={(newVal) => {
                setSelectedCourse("");
                setSelectedLevel(newVal);
              }}
              label="Název kurzu (úroveň a obsah)"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFSelect
              options={
                selectedLevel.length > 0
                  ? info?.courses[selectedLevel].courses
                      .filter((c) => c.noSignUp !== true)
                      .map((c) => {
                        return {
                          key: c.code,
                          value: c.name,
                        };
                      }) || []
                  : []
              }
              value={selectedCourse}
              onChange={setSelectedCourse}
              label="Typ kurzu (rozsah a intenzita)"
            />
          </Grid>
          {selectedCourse.length > 0 && (
            <Grid item xs={12}>
              {(getCoursePrice(selectedCourseInfo, selectedLevel.substring(2, 4)) || 0) <
                (getCourseBasePrice(selectedCourseInfo, selectedLevel.substring(2, 4)) || 0) &&
                (getCoursePrice(selectedCourseInfo, selectedLevel.substring(2, 4)) || 0) > 0 && (
                  <EFCheckbox
                    checked={!useBasePrice}
                    onClick={() => setUseBasePrice(!useBasePrice)}
                    label="Chci zvýhodněnou cenu First Minute"
                    subLabel={`Jsem si vědom(a), že touto volbou se zříkám nároku na pozdější odstoupení od smlouvy a vrácení kurzovného.`}
                  />
                )}
              <h3 style={{ fontFamily: "Dosis, Roboto, sans-serif", fontWeight: "bold" }}>Údaje o vybraném kurzu:</h3>
              <Table>
                <TableHead>
                  <Hidden xsDown>
                    <TableRow>
                      <TableCell className={classes.tableCell}>kurz</TableCell>
                      <TableCell className={classes.tableCell}>termín</TableCell>
                      <TableCell className={classes.tableCell}>rozvrh</TableCell>
                      <TableCell className={classes.tableCell}>cena</TableCell>
                    </TableRow>
                  </Hidden>
                  <TableRow>
                    <Hidden xsDown>
                      <TableCell className={classes.tableCell}>
                        {info?.courses[selectedLevel].title} {info?.courses[selectedLevel].type}, {selectedCourseInfo.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>{selectedCourseInfo.dates}</TableCell>
                      <TableCell className={classes.tableCell}>{selectedCourseInfo.times}</TableCell>
                      <TableCell className={classes.tableCell}>
                        {(getCoursePrice(selectedCourseInfo, selectedLevel.substring(2, 4)) || 0) > 0 ? (
                          useBasePrice ? (
                            separateThousands(getCourseBasePrice(selectedCourseInfo, selectedLevel.substring(2, 4)))
                          ) : (
                            separateThousands(getCoursePrice(selectedCourseInfo, selectedLevel.substring(2, 4))) + " Kč"
                          )
                        ) : (
                          <>Ohledně ceny budete kontaktován</>
                        )}
                      </TableCell>
                    </Hidden>
                    <Hidden smUp>
                      <TableCell className={classes.tableCell}>
                        <b>
                          {info?.courses[selectedLevel].title} {info?.courses[selectedLevel].type} {selectedCourseInfo.name}{" "}
                          kurz
                        </b>
                        <br />
                        Termín: {selectedCourseInfo.dates}
                        <br />
                        Rozvrh: {selectedCourseInfo.times}
                        <br />
                        Cena:{" "}
                        {useBasePrice
                          ? separateThousands(getCourseBasePrice(selectedCourseInfo, selectedLevel.substring(2, 4)))
                          : separateThousands(getCoursePrice(selectedCourseInfo, selectedLevel.substring(2, 4)))}{" "}
                        Kč
                      </TableCell>
                    </Hidden>
                  </TableRow>
                </TableHead>
              </Table>
            </Grid>
          )}
        </Grid>
        <h3 style={{ fontFamily: "Dosis, Roboto, sans-serif", fontWeight: "bold" }}>Údaje o účastníkovi kurzu:</h3>
        <br />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              <EFCheckbox
                checked={formState.personType === "person"}
                onClick={() => setFormState({ ...formState, personType: "person" })}
                label="Fyzická osoba"
                subLabel=""
              />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <EFCheckbox
                checked={formState.personType === "company"}
                onClick={() => setFormState({ ...formState, personType: "company" })}
                label="OSVČ nebo firma"
                subLabel=""
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <EFTextField
              value={formState.name}
              onChange={(val) => setFormState({ ...formState, name: val })}
              label="Křestní jméno"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFTextField
              value={formState.surname}
              onChange={(val) => setFormState({ ...formState, surname: val })}
              label="Příjmení"
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={4}>
            <EFTextField
              value={formState.bornDate}
              onChange={(val) => setFormState({ ...formState, bornDate: val })}
              label="Datum narození"
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12} md={8} lg={4}>
            <EFTextField
              value={formState.street}
              onChange={(val) => setFormState({ ...formState, street: val })}
              label="Ulice"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <EFTextField
              value={formState.houseNum}
              onChange={(val) => setFormState({ ...formState, houseNum: val })}
              label="Číslo popisné"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={8} lg={4}>
            <EFTextField
              value={formState.city}
              onChange={(val) => setFormState({ ...formState, city: val })}
              label="Město"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <EFTextField
              value={formState.zip}
              onChange={(val) => setFormState({ ...formState, zip: val })}
              label="PSČ"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFTextField
              value={formState.mail}
              onChange={(val) => setFormState({ ...formState, mail: val })}
              label="Email"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFTextField
              value={formState.phone}
              onChange={(val) => setFormState({ ...formState, phone: val })}
              label="Mobil"
              fullWidth
            />
          </Grid>
          {formState.personType === "company" && (
            <>
              <Grid item xs={12} md={6} lg={4}>
                <EFTextField
                  value={formState.companyNumber}
                  onChange={(val) => setFormState({ ...formState, companyNumber: val })}
                  label="IČ"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <EFTextField
                  value={formState.taxId}
                  onChange={(val) => setFormState({ ...formState, taxId: val })}
                  label="DIČ"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <EFTextField
                  value={formState.companyName}
                  onChange={(val) => setFormState({ ...formState, companyName: val })}
                  label="Název firmy"
                  fullWidth
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <EFTextField
              value={formState.info}
              onChange={(val) => setFormState({ ...formState, info: val })}
              label="Doplňující informace"
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <EFCheckbox
              checked={formState.differentBillingAddress}
              onClick={() => setFormState({ ...formState, differentBillingAddress: !formState.differentBillingAddress })}
              label="Přeji si fakturovat na jinou adresu"
              subLabel=""
            />
          </Grid>
        </Grid>
        {formState.differentBillingAddress && (
          <>
            <h3 style={{ fontFamily: "Dosis, Roboto, sans-serif", fontWeight: "bold" }}>Fakturační adresa</h3>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <EFTextField
                  value={formState.billingName}
                  onChange={(val) => setFormState({ ...formState, billingName: val })}
                  label="Jméno"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <EFTextField
                  value={formState.billingSurname}
                  onChange={(val) => setFormState({ ...formState, billingSurname: val })}
                  label="Jméno"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8} lg={4}>
                <EFTextField
                  value={formState.billingStreet}
                  onChange={(val) => setFormState({ ...formState, billingStreet: val })}
                  label="Ulice"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <EFTextField
                  value={formState.billingHouseNum}
                  onChange={(val) => setFormState({ ...formState, billingHouseNum: val })}
                  label="Číslo popisné"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={8} lg={4}>
                <EFTextField
                  value={formState.billingCity}
                  onChange={(val) => setFormState({ ...formState, billingCity: val })}
                  label="Město"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <EFTextField
                  value={formState.billingZip}
                  onChange={(val) => setFormState({ ...formState, billingZip: val })}
                  label="PSČ"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <EFTextField
                  value={formState.billingCompanyNumber}
                  onChange={(val) => setFormState({ ...formState, billingCompanyNumber: val })}
                  label="IČ"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <EFTextField
                  value={formState.billingTaxId}
                  onChange={(val) => setFormState({ ...formState, billingTaxId: val })}
                  label="DIČ"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <EFTextField
                  value={formState.billingCompanyName}
                  onChange={(val) => setFormState({ ...formState, billingCompanyName: val })}
                  label="Název firmy"
                  fullWidth
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ fontFamily: "Dosis, Roboto, sans-serif", color: "#122e5c" }}>
            <Checkbox
              checked={agreeWithConditions}
              onChange={() => setAgreeWithConditions(!agreeWithConditions)}
              color="primary"
            />{" "}
            Souhlasím s{" "}
            <u style={{ cursor: "pointer" }} onClick={() => window.open("/conditions", "_blank")}>
              Obchodními podmínkami
            </u>{" "}
            a{" "}
            <u style={{ cursor: "pointer" }} onClick={() => window.open("/privacyPolicy", "_blank")}>
              Zpracováním osobních údajů
            </u>
            .
            <br />
            <br />
          </Grid>
        </Grid>
        <EFButton
          style={{
            float: "right",
            minWidth: width < 454 ? 200 : 320,
            fontFamily: "Dosis, Roboto, sans-serif",
            fontWeight: "bold",
            padding: "6px 44px",
            fontSize: "1.6rem",
            borderRadius: 12,
          }}
          loading={loading}
          loadingText="Odesílám přihlášku"
          onClick={submit}
          label={width < 454 ? "Odeslat přihlášku" : "Odeslat závaznou přihlášku"}
          disabled={!isFormValid}
        />
      </Container>
      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={12000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert onClose={() => setOpenAlert(false)} severity="success">
          Gratulujeme! Vaše objednávka byla odeslána. Pokud do několika minut neobdržíte potvrzovací email s informacemi o
          uhrazení kurzovného, zkontrolujte prosím nevyžádanou poštu a případně nás kontaktujte.
        </Alert>
      </Snackbar>
    </div>
  );
}
