import React, { useState } from "react";
import { Container, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import EFTextField from "../../Components/EFTextField";
import EFButton from "../../Components/EFButton";
import { api } from "../../Utils/ApiService";
import useWindowDimensions from "../../Hooks/GetWindowDimensions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "#FBF6E5",
      paddingTop: 1,
      paddingBottom: 96,
    },
  })
);

const emptyFormState = {
  name: "",
  mail: "",
  phone: "",
  question: "",
};

export default function ContactForm() {
  const classes = useStyles();
  const [formState, setFormState] = useState(emptyFormState);
  const [loading, setLoading] = useState(false);

  const { width } = useWindowDimensions();

  const isFormValid = formState.name.length > 0 && formState.mail.length > 0 && formState.question.length > 0;
  const submit = () => {
    setLoading(true);
    api.post(
      "askQuestion",
      {},
      {
        name: formState.name,
        mail: formState.mail,
        phone: formState.phone,
        question: formState.question,
      },
      {
        success: () => {
          setFormState(emptyFormState);
          alert("Váš dotaz byl odeslán. Co nejdříve Vás kontaktujeme.");
          setLoading(false);
        },
        error: () => {
          alert("Při zasílání dotazu došlo k chybě, zkuste to prosím později.");
          alert("Váš dotaz byl odeslán. Co nejdříve Vás kontaktujeme.");
        },
      }
    );
  };

  return (
    <div className={classes.root} id="contactForm">
      <Container maxWidth="lg">
        <h2>Napište mi</h2>
        <p>
          Ať už potřebujete s čímkoliv poradit, nenašli jste požadovaný kurz, chcete navázat spolupráci, nebo se třeba jen
          chcete zastavit na kávu...
        </p>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <EFTextField
              value={formState.name}
              onChange={(val) => setFormState({ ...formState, name: val })}
              label="Jméno a příjmení"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <EFTextField
              value={formState.mail}
              onChange={(val) => setFormState({ ...formState, mail: val })}
              label="Email"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <EFTextField
              value={formState.phone}
              onChange={(val) => setFormState({ ...formState, phone: val })}
              label="Telefon"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <EFTextField
              value={formState.question}
              onChange={(val) => setFormState({ ...formState, question: val })}
              label="Dotaz / námět"
              fullWidth
              multiline
              rows={8}
            />
          </Grid>
        </Grid>
        <br />
        <br />
        <EFButton
          style={{
            float: "right",
            minWidth: width < 420 ? 200 : 320,
            fontFamily: "Dosis, Roboto, sans-serif",
            fontWeight: "bold",
            padding: "6px 44px",
            fontSize: "1.6rem",
            borderRadius: 12,
          }}
          loading={loading}
          loadingText={width < 420 ? "Odesílám dotaz" : "Odesílám dotaz / námět"}
          disabled={!isFormValid}
          onClick={submit}
          label={width < 420 ? "Odeslat dotaz" : "Odeslat dotaz / námět"}
        />
      </Container>
    </div>
  );
}
