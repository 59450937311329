import React from "react";
import { Container, createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { Email, Phone, WhatsApp } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFFAF6",
      width: "100%",
      paddingTop: 64,
      paddingBottom: 64,
    },
    photo: {
      [theme.breakpoints.up("md")]: {
        height: 735,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    infoLine: {
      fontFamily: "Dosis, Roboto, sans-serif",
      marginBlockStart: 12,
    },
    map: {
      border: "none",
      width: "84%",
      height: 320,
    },
  })
);

export default function VisitCard() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} md={7}>
            <h2 className={classes.infoLine}>
              <b>Jazykové studio EnglishFit</b>
            </h2>
            <h3 className={classes.infoLine}>Mgr. Marek Chocholatý</h3>
            <h3 className={classes.infoLine}>Horova 1859, 666 03 Tišnov</h3>
            <h3 className={classes.infoLine}>IČ: 47345128, DIČ: CZ6903264588 </h3>
            <h3 className={classes.infoLine}>č.ú. 8436270841/5500 Raiffeisenbank </h3>
            <h3 className={classes.infoLine}>
              <Phone />/<WhatsApp /> +420 608 821 819&nbsp;&nbsp;&nbsp; &nbsp;
              <Email /> info@englishfit.cz
            </h3>
            <h4 className={classes.infoLine}>
              Podnikající fyzická osoba zapsaná v Živnostenském rejstříku u MěÚ Tišnov
              <br /> od 7.12.2007 pod evidenčním číslem 370204-9007 je plátcem DPH.
            </h4>
            <br />
            <iframe
              title="map"
              className={classes.map}
              src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Horova%201859,%20666%2003%20Ti%C5%A1nov+(My%20Business%20Name)&t=&z=14&ie=UTF8&iwloc=B&output=embed"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <img src="/img/profile2.png" alt="profile" className={classes.photo} />
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
    </div>
  );
}
