import React, { useEffect, useState } from "react";
import { Container, createStyles, Hidden, makeStyles, Theme } from "@material-ui/core";
import data from "../../Data/Home/Recension.json";
import SwipeableViews from "react-swipeable-views";
import Flex from "../../Components/Flex";
import { ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 32,
      paddingBottom: 64,
      backgroundColor: "#FFFBEE",
      position: "relative",
    },
    recAuthor: {
      color: "#454545",
      fontFamily: "Dosis, Roboto, sans-serif",
      fontSize: "0.9rem",
      textAlign: "right",
    },
    recText: {
      fontFamily: "Dosis, Roboto, sans-serif",
      fontWeight: "bold",
      fontSize: "1.4rem",
      color: "#122E5C",
    },
    recArrowContainer: {
      width: "10%",
      position: "relative",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    recContainer: {
      [theme.breakpoints.up("md")]: {
        width: "80%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    title: {
      color: "#155dc4",
      fontFamily: "Roboto, sans-serif",
      fontSize: "2.1rem",
      fontWeight: "bold",
    },
    arrowIcon: {
      width: 122,
      height: 122,
      transition: "all 250ms ease-in-out",
      color: "#FAEEC7",
      "&:hover": {
        color: "#E9DCB4",
      },
    },
    sticker: {
      position: "absolute",
      bottom: -62,
      right: 32,
    },
    stickerImage: {
      height: 128,
    },
  })
);

export default function Recension() {
  const classes = useStyles();
  const [index, setIndex] = useState(10);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIndex((index + 1) % data.length);
    }, 12000);

    return () => clearTimeout(timeoutId);
  }, [index]);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" style={{ position: "relative" }}>
        <div className={classes.sticker}>
          <a target="_blank" rel="noreferrer" href="https://www.jazykovky.cz/certifikaty/osobni-doporuceni-EnglishFit-Mgr-Marek-Chocholaty-Tisnov-pjRH7V4v.pdf">
            <img className={classes.stickerImage} src="https://www.jazykovky.cz/gfx/icon-garantovano-js.png" alt="certifikát www.Jazykovky.cz" />
          </a>
        </div>
        <h2 id="reference">REFERENCE</h2>
        {
          <Flex>
            <Hidden smDown>
              <div className={classes.recArrowContainer} onClick={() => setIndex((index + data.length - 1) % data.length)}>
                <ChevronLeftRounded className={classes.arrowIcon} />
              </div>
            </Hidden>
            <div className={classes.recContainer}>
              {
                <SwipeableViews index={index} onChangeIndex={setIndex}>
                  {data.map((rec, i) => (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <div className={classes.recText}>{rec.text}</div>
                      <br />
                      <div className={classes.recAuthor}>{rec.author}</div>
                    </div>
                  ))}
                </SwipeableViews>
              }
            </div>
            <Hidden smDown>
              <div className={classes.recArrowContainer} onClick={() => setIndex((index + 1) % data.length)}>
                <ChevronRightRounded className={classes.arrowIcon} />
              </div>
            </Hidden>
          </Flex>
        }
      </Container>
    </div>
  );
}
