import React, { useState } from "react";
import { Container, createStyles, Grid, makeStyles, Snackbar, Theme } from "@material-ui/core";
import EFTextField from "../../Components/EFTextField";
import EFButton from "../../Components/EFButton";
import EFCheckbox from "../../Components/EFCheckbox";
import EFSelect from "../../Components/EFSelect";
import { api } from "../../Utils/ApiService";
import useWindowDimensions from "../../Hooks/GetWindowDimensions";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "#FBF6E5",
      paddingTop: 32,
      paddingBottom: 128,
    },
  })
);

export default function Form() {
  const classes = useStyles();
  const [selectedLevels, setSelectedLevels] = useState<string[]>([]);
  const [type, setType] = useState("");
  const [form, setForm] = useState("");
  const [style, setStyle] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();
  const [openAlert, setOpenAlert] = useState(false);

  const submit = () => {
    if (isFormValid) {
      setLoading(true);
      api.post(
        "/privateSignUp",
        {},
        {
          name: name,
          address: address,
          phone: phone,
          mail: mail,
          levels: selectedLevels.sort().join(", "),
          type: type,
          form: form,
          content: style,
          additionalInfo: info,
        },
        {
          success: () => {
            setSelectedLevels([]);
            setType("");
            setForm("");
            setStyle("");
            setName("");
            setMail("");
            setPhone("");
            setAddress("");
            setInfo("");
            setOpenAlert(true);
            setLoading(false);
          },
          error: () => {
            alert("Při odesílání popdávky došlo k chybě, zkuste to prosím znovu.");
            setLoading(false);
          },
        }
      );
    }
  };

  const isFormValid =
    selectedLevels.length > 0 &&
    address.length > 0 &&
    type.length > 0 &&
    form.length > 0 &&
    style.length > 0 &&
    name.length > 0 &&
    mail.length > 0 &&
    phone.length > 0;

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <h2 id="poptavka">POPTÁVKOVÝ FORMULÁŘ</h2>
        <p>
          Pokud máte zájem o další informace nebo si přejete přímo domluvit výuku, vyplňte prosím níže uvedený formulář, abych
          mohl vaše požadavky posoudit a oslovit vás s konkrétní nabídkou.
        </p>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <EFTextField value={name} onChange={setName} fullWidth label="Název firmy / Jméno a příjmení" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFTextField value={address} onChange={setAddress} fullWidth label="Sídlo firmy / Adresa bydliště" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFTextField value={phone} onChange={setPhone} fullWidth label="Telefon" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFTextField value={mail} onChange={setMail} fullWidth label="Email" />
          </Grid>
          <Grid item xs={12} md={6}>
            <p>
              <b>Současná jazyková úroveň:</b>
              <br />
              <span style={{ fontSize: "0.8rem" }}>
                v případě firmy nebo skupiny s větším počtem zájemců vyznačte všechny odhadované úrovně
              </span>
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <br />
            <EFCheckbox
              checked={selectedLevels.includes("NN")}
              onClick={() =>
                selectedLevels.includes("NN")
                  ? setSelectedLevels(selectedLevels.filter((sl) => sl !== "NN"))
                  : setSelectedLevels([...selectedLevels, "NN"])
              }
              label="Nevím"
              subLabel="Potřebuji vstupní test nebo pohovor."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFCheckbox
              checked={selectedLevels.includes("A0")}
              onClick={() =>
                selectedLevels.includes("A0")
                  ? setSelectedLevels(selectedLevels.filter((sl) => sl !== "A0"))
                  : setSelectedLevels([...selectedLevels, "A0"])
              }
              label="A0 - úplný začátečník"
              subLabel="Vůbec nerozumím ani nemluvím anglicky."
            />
            <br />
            <EFCheckbox
              checked={selectedLevels.includes("A1")}
              onClick={() =>
                selectedLevels.includes("A1")
                  ? setSelectedLevels(selectedLevels.filter((sl) => sl !== "A1"))
                  : setSelectedLevels([...selectedLevels, "A1"])
              }
              label="A1 - nepravý začátečník"
              subLabel="Rozumím a mluvím jen s obtížemi."
            />
            <br />
            <EFCheckbox
              checked={selectedLevels.includes("A2")}
              onClick={() =>
                selectedLevels.includes("A2")
                  ? setSelectedLevels(selectedLevels.filter((sl) => sl !== "A2"))
                  : setSelectedLevels([...selectedLevels, "A2"])
              }
              label="A2 - mírně pokročilý"
              subLabel="Dokážu jednoduše komunikovat a orientovat se ve známých situacích, i když ještě s obtížemi."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EFCheckbox
              checked={selectedLevels.includes("B1")}
              onClick={() =>
                selectedLevels.includes("B1")
                  ? setSelectedLevels(selectedLevels.filter((sl) => sl !== "B1"))
                  : setSelectedLevels([...selectedLevels, "B1"])
              }
              label="B1 - středně pokročilý"
              subLabel="Umím vytvářet souvětí a rozumím hlavním myšlenkám, ale nezvládám složitější gramatiku a slovní zásobu."
            />
            <br />
            <EFCheckbox
              checked={selectedLevels.includes("B2")}
              onClick={() =>
                selectedLevels.includes("B2")
                  ? setSelectedLevels(selectedLevels.filter((sl) => sl !== "B2"))
                  : setSelectedLevels([...selectedLevels, "B2"])
              }
              label="B2 - více pokročilý"
              subLabel="Umím komunikovat bez větších obtíží, ale stále dělám chyby a někdy špatně rozumím."
            />
            <br />
            <EFCheckbox
              checked={selectedLevels.includes("C1")}
              onClick={() =>
                selectedLevels.includes("C1")
                  ? setSelectedLevels(selectedLevels.filter((sl) => sl !== "C1"))
                  : setSelectedLevels([...selectedLevels, "C1"])
              }
              label="C1 - pokročilý"
              subLabel="Mluvím a rozumím velmi dobře, výjimečně udělám chybu nebo neporozumím zcela přesně. "
            />
          </Grid>
          <Grid item xs={12}>
            <p>
              <b>Mám(e) zájem o:</b>
            </p>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <EFSelect
              label="Typ výuky"
              onChange={setType}
              value={type}
              options={[
                { key: "CompanyIndividual", value: "firemní individuální" },
                { key: "CompanyGroup", value: "firemní skupinová" },
                { key: "PrivateIndividual", value: "soukromá individuální" },
                { key: "PrivateGroup", value: "soukromá skupinová" },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <EFSelect
              label="Forma výuky"
              onChange={setForm}
              value={form}
              options={[
                { key: "distance", value: "distanční" },
                { key: "presence", value: "prezenční" },
                { key: "both", value: "nezáleží" },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <EFSelect
              label="Obsah výuky"
              onChange={setStyle}
              value={style}
              options={[
                { key: "Grammar", value: "gramatika" },
                { key: "Conversation", value: "konverzace" },
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <EFTextField value={info} onChange={setInfo} label="Další požadavky" fullWidth multiline rows={4} />
          </Grid>
        </Grid>
        <br />
        <EFButton
          style={{
            float: "right",
            minWidth: width < 420 ? 200 : 320,
            fontFamily: "Dosis, Roboto, sans-serif",
            fontWeight: "bold",
            padding: "6px 44px",
            fontSize: "1.6rem",
            borderRadius: 12,
          }}
          disabled={!isFormValid}
          onClick={submit}
          loading={loading}
          loadingText="Odesílám objednávku"
          label={width < 420 ? "Odeslat poptávku" : "Odeslat nezávaznou poptávku"}
        />
      </Container>
      <Snackbar
        open={openAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={12000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert onClose={() => setOpenAlert(false)} severity="success">
          Děkujeme! Vaše poptávka byla odeslána. Pokud do několika minut neobdržíte potvrzovací email, zkontrolujte prosím
          nevyžádanou poštu a případně nás kontaktujte.
        </Alert>
      </Snackbar>
    </div>
  );
}
