import React, { useState } from "react";
import { createStyles, Hidden, IconButton, makeStyles, Theme } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import RightDrawer from "./SwipeableNavbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "relative",
    },
    bigLogo: {
      height: 67,
      marginLeft: 32,
      marginTop: 16,
      marginBottom: 4,
      cursor: "pointer",
      // eslint-disable-next-line
      ["@media (max-width:340px)"]: {
        height: 58,
      },
      // eslint-disable-next-line
      ["@media (max-width:305px)"]: {
        height: 50,
      },
    },
    menuBox: {
      display: "flex",
    },
    menuItem: {
      fontFamily: "LudicrousStencil, Montserrat, Roboto, sans-serif",
      color: "#122E5C",
      cursor: "pointer",
      margin: 8,
      marginLeft: 32,
      marginRight: 32,
      fontSize: "1.4rem",
      alignSelf: "center",
      textAlign: "center",
      "&:hover": {
        color: "#394E6F",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "1.2rem",
        marginLeft: 24,
        marginRight: 24,
      },
    },
    selected: {
      fontFamily: "LudicrousStencil, Montserrat, Roboto, sans-serif",
      backgroundColor: "#122E5C",
      color: "white",
      cursor: "pointer",
      margin: 8,
      marginLeft: 32,
      marginRight: 32,
      fontSize: "1.4rem",
      alignSelf: "center",
      textAlign: "center",
      paddingTop: 6,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 8,
      //   display: "inline-block",
      "&:hover": {
        color: "white",
      },
    },
    gradient: {
      position: "absolute",
      bottom: -4,
      zIndex: 999,
      left: 0,
      width: "100%",
      height: 4,
      background: "linear-gradient(to bottom, grey, transparent)",
    },
    smallNavbar: {
      display: "flex",
      position: "fixed",
      bottom: 0,
      zIndex: 999,
      backgroundColor: "white",
      borderBottom: "1px solid grey",
      width: "100%",
    },
    menuIcon: {
      top: "20px",
      right: "22px",
      position: "absolute",
    },
  })
);

function CommonMenu() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <Hidden smDown>
        <div
          className={`${classes.menuItem} ${location.pathname === "/" ? classes.selected : ""}`}
          onClick={() => history.push("/")}
        >
          ÚVOD
        </div>
        <div
          className={`${classes.menuItem} ${location.pathname.includes("teaching") ? classes.selected : ""}`}
          onClick={() => history.push("/teaching")}
        >
          VÝUKA
        </div>
        <div
          className={`${classes.menuItem} ${location.pathname.includes("publicTeaching") ? classes.selected : ""}`}
          onClick={() => history.push("/publicTeaching")}
        >
          SKUPINOVÉ KURZY
          <br />
          PRO VEŘEJNOST
        </div>
        <div
          className={`${classes.menuItem} ${location.pathname.includes("companyPrivateTeaching") ? classes.selected : ""}`}
          onClick={() => history.push("/companyPrivateTeaching")}
        >
          INDIVIDUÁLNÍ
          <br />A FIREMNÍ KURZY
        </div>
        <div
          className={`${classes.menuItem} ${location.pathname.includes("contact") ? classes.selected : ""}`}
          onClick={() => history.push("/contact")}
        >
          KONTAKT
        </div>
        <div
          className={`${classes.menuItem} ${location.pathname.includes("studentZone") ? classes.selected : ""}`}
          onClick={() => history.push("/studentZone")}
        >
          STUDENTSKÁ
          <br /> ZÓNA
        </div>
      </Hidden>
      <Hidden mdUp>
        <IconButton className={classes.menuIcon} onClick={() => setMenuOpen(!menuOpen)} aria-label="show menu">
          <Menu />
        </IconButton>
        <RightDrawer open={menuOpen} setOpen={setMenuOpen} />
      </Hidden>
    </>
  );
}

export default function Header() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <div className={classes.root}>
        <div className={classes.gradient} />
        <img className={classes.bigLogo} onClick={() => history.push("/")} src="/img/logo.svg" alt="bigLogo" />
        <div style={{ flexGrow: 1 }} />
        <div className={`${classes.menuBox}`}>
          <CommonMenu />
        </div>
      </div>
    </>
  );
}
