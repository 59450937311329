import React, { useState } from "react";
import { Container, createStyles, Grid, Hidden, makeStyles, Theme } from "@material-ui/core";
import EFButton from "../../Components/EFButton";
import Expand from "react-expand-animated";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "#FFFBEE",
      paddingTop: 32,
      paddingBottom: 16,
    },
    list: {
      lineHeight: 1.7,
      color: "#122e5c",
      letterSpacing: "0.00938em",
      fontFamily: "Dosis, Roboto, sans-serif",
      fontSize: "1.2rem",
      paddingLeft: 20,
      textAlign: "justify",
    },
    onlineImage: {
      width: "100%",
      marginTop: 38,
      marginBottom: 40,
    },
  })
);

export default function Forms() {
  const classes = useStyles();
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <h2 id="online">FORMY VÝUKY SE MĚNÍ, KVALITA ZŮSTÁVÁ</h2>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <img src="/img/online.jpg" alt="online teaching" className={classes.onlineImage} />
            <p>
              Doba pandemie přesunula prezenční výuku k výuce distanční, což je velká výzva jak pro učitele, tak studenta.
              Domnívám se však, že z pohledu kvality výuky se nic nemění, jen se obě strany musí technicky dostatečně připravit,
              aby mohly potlačit negativa a posílit pozitiva. Docela mě v této souvislosti překvapili někteří moji studenti,
              kteří by distanční formu výuky neměnili na prezenční ani v případě možnosti výběru. Dodalo mi to novou energii i
              pocit zadostiučinění, že učit se novým přístupům se vyplatí.
            </p>
            <p>
              Momentálně preferuji živou online výuku ve všech typech svých kurzů. Pouze ve firemních a individuálních kurzech
              nabízím alternativně také výuku docházkovou.
            </p>
            <Hidden smDown>
              {!detailsExpanded && (
                <EFButton
                  label="Osobní zkušenosti s distanční výukou"
                  style={{ width: "60%", fontFamily: "Dosis, Roboto, sans-serif", fontSize: "1.2rem" }}
                  onClick={() => setDetailsExpanded(true)}
                />
              )}
            </Hidden>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>
              Srovnání distanční výuky / živě online
              <br /> s výukou prezenční / docházkovou
            </h3>
            <p>
              <b>Plusy</b>
            </p>
            <ul className={classes.list}>
              <li>virtuální tabule znamená konec otrockého opisování z klasické tabule</li>
              <li>sdílení textových či zvukových poznámek, s nimiž lze dále pracovat</li>
              <li>procházení aktuálních zpráv, článků, audio a video nahrávek</li>
              <li>eliminace plýtvání času a peněz fyzickým dojížděním na místo</li>
              <li>vše bez stresu, v pohodě a ekologicky</li>
              <li>už žádná zameškaná lekce, místní a časová flexibilita</li>
              <li>učíte se, kdekoli jste online - doma, v práci, na dovolené</li>
              <li>výuku lze na vyžádání nahrát a sdílet, příp. nahradit v souběžném kurzu</li>
              <li>ochrana před šířením infekce, takže se můžete učit, i když vám zrovna není do tance</li>
            </ul>
            <p>
              <b>Mínusy</b>
            </p>
            <ul className={classes.list}>
              <li>
                nutnost zajistit odpovídající provozní podmínky přenosu, především rychlost připojení k internetu, vlastní
                soukromí, kvalitní technické zařízení (notebook, PC, tablet, mobilní telefon), sluchátka, mikrofon a webovou
                kameru
              </li>
              <li id="anchor1">
                výjimečně může dojít ke krátkodobému přetížení sítě, projevující se částečným zamrznutím obrazu při zachování
                plynulého přenosu zvuku
              </li>
            </ul>
            <br />
            <Hidden mdUp>
              {!detailsExpanded && (
                <EFButton
                  label="Osobní zkušenosti s distanční výukou"
                  style={{ width: "60%", fontFamily: "Dosis, Roboto, sans-serif", fontSize: "1.2rem" }}
                  onClick={() => setDetailsExpanded(true)}
                />
              )}
            </Hidden>
          </Grid>
        </Grid>
        <br />
        <Expand open={detailsExpanded}>
          <h3>Osobní zkušenosti s distanční výukou</h3>
          <p>
            Podle průzkumů asi polovina práceschopné české populace má nedůvěru k online výuce a zuby nehty se drží klasické
            kontaktní výuky ve třídě. Je to do značné míry spojeno s nedostatečnou mírou zvládnutí digitálních technologií v
            naší společnosti umocněnou bohužel i řadou negativních zkušeností s online výukou během první vlny pandemie na jaře
            2020.
          </p>
          <p>
            Osobně se snažím nacházet na všem špatném vždy něco dobrého. Je to jakýsi pud sebezáchovy. Každá překážka je zároveň
            výzvou k jejím překonání, což mě většinou v konečném důsledku ještě posílí. Pokud to aplikuju na pandemii, tak jsme
            nuceni omezovat reálné kontakty a nahrazovat je kontakty virtuálními. Zároveň ale rozvíjíme alternativní formy
            komunikace, které mohou v budoucnosti, kdy už budeme mít možnost volby, získávat na oblibě díky výhodám, které
            přinášejí.
          </p>
          <p>
            Samozřejmě i zde platí, že když dva dělají totéž, nemusí to být zdaleka totéž. Že se vám při zmínce o distanční
            výuce vybaví jen neostré, špatně osvětlené video vystresovaného lektora, kterému je stěží rozumět a který neví, kam
            zapsat nové obraty z lekce, a zmateného studenta bez webkamery, který si dělá poznámky do papírového bloku bez
            kontroly tabule? Na nic podobného u mě nenarazíte.
          </p>
          <p>
            Pro oživení atmosféry střídám pohled na účastníky s pohledem na plochu obrazovky, na které sdílím virtuální tabuli
            se všemi poznámkami tak, jak je to na opravdové tabuli ve skutečné učebně. Obrovskou výhodou oproti prezenční výuce
            ale je, že můžeme na obrazovce na dálku sdílet cokoli z internetu nebo z pevného disku počítače. Vybrané obrázky,
            zvuk a video lze navíc umístit na virtuální tabuli, jejiž obsah je nepřetržitě aktualizován a ukládán do cloudu,
            přičemž všichni účastníci mají ke všem lektorovým poznámkám online přístup z libovolného zařízení i mimo živou
            výuku. Pro výuku velice často vybírám aktuální články či videoreportáže z online médií, úryvky z filmů nebo poslech
            písniček s doplněním textu. Navíc si u mě student může předem objednat nahrávku lekce a nahradit tak zameškanou
            výuku.
          </p>
          <p>
            Student tak neplýtvá drahocenným časem lekce na otrocké opisování z tabule a plně se soustředí na novou látku
            teoreticky a prakticky, neb ví, že poznámky si může kdykolik potom projít. Navíc je má k dispozici v elektronické
            podobě a může s nimi dále pracovat, např. vytvářením vlastního přehledu slovní zásoby.
          </p>
          <p>
            Pohodlí a pohoda je pro mnoho z nás až na prvním místě. Proč tedy plýtvat časem a penězi docházkou do prezenčních
            kurzů, psychicky se stresovat fyzickým dojížděním do kurzu a zpět domů, riskovat uváznutí v dopravní zácpě, dlouho
            hledat místo k zaparkování? Není lepší šetřit životní prostředí, duševní zdraví, čas a peníze?
          </p>
          <p>
            V neposlední řadě přispívá ke stále větší oblibě distanční výuky pocit flexibility. Ať jste doma, v práci nebo na
            dovolené, můžete se pohodlně a přitom plnohodnotně účastnit živé výuky. Jde jen o to zajistit si dostatek soukromí
            během lekce a odpovídající technické podmínky včetně dostatečné rychlosti internetového připojení.
          </p>
          <p>
            Pokud se Vám schůzka obrazem či zvukem seká nebo vás opakovaně trápí audio či video příjem, většinou je třeba
            obstarat kvalitnější internetové připojení a výkonnější hardware - PC, notebook či tablet s kvalitní webkamerou,
            sluchátky a mikrofonem. Tato investice se vám v dnešní době velice brzy vrátí.
          </p>
          <p
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDetailsExpanded(false);
              // @ts-ignore
              window.scrollTo({
                top: document.getElementById("anchor1")?.offsetTop,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            <u>Skrýt sekci</u>
          </p>
        </Expand>
        <br />
        <br />
      </Container>
    </div>
  );
}
